import {useEffect, useRef} from "react";
import {Mesh, NormalBlending, OrthographicCamera, PlaneBufferGeometry, RawShaderMaterial, WebGLRenderer} from "three";
import {GraphicsManager} from "../GraphicsManager";
import {GraphicsScene} from "../scenes/GraphicsScene";

const VertexShader = `
    precision mediump float;
    attribute vec3 position;
    attribute vec2 uv;
    
    varying vec2 vUv;
    
    void main() {
        vUv = uv;
        gl_Position = vec4(position * 2.0, 1.0);
    }
`;

const FragmentShader = `
    precision highp float;
    uniform float offset;
    uniform float intensity;
    
    varying vec2 vUv;
    
    float rand(vec2 co){
        return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }
    
    void main() {
        float noise = rand(vUv + vec2(offset * 1.2, offset * -1.2)) * intensity;
        gl_FragColor = vec4(0.0, 0.0, 0.0, noise);
    }
`;

/**
 * Сцена с шумом
 */
class NoiseScene extends GraphicsScene {

    /**
     * Ортокамера для рендера (в шейдере не используется)
     * @private
     */
    private camera?: OrthographicCamera;

    /**
     * Плейн для рендера
     * @private
     */
    private mesh?: Mesh;

    /**
     * Материал с шейдером шума
     * @private
     */
    private material?: RawShaderMaterial;

    /**
     * Интенсивность шума
     * @private
     */
    private intensity: number = 1.0;

    /**
     * Внутренний сдвиг семпла шума
     * @private
     */
    private offset: number = 1;

    /**
     * Создание модели и материала
     * @protected
     */
    protected async load() {
        this.camera = new OrthographicCamera();
        this.material = new RawShaderMaterial({
            vertexShader: VertexShader,
            fragmentShader: FragmentShader,
            transparent: true,
            blending: NormalBlending,
            depthWrite: false,
            depthTest: false,
            uniforms: {
                offset: {
                    value: 0.0,
                },
                intensity: {
                    value: this.intensity,
                }
            }
        })
        this.mesh = new Mesh(new PlaneBufferGeometry(), this.material);
        this.mesh.frustumCulled = false;
    };

    /**
     * Обновление логики материала
     * @param delta
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {
        this.offset = (this.offset + 0.01 * delta) % 1.0;

        if (this.material) {
            this.material.uniforms.offset.value = this.offset;
            this.material.uniforms.intensity.value = this.intensity;
        }
    }

    /**
     * Рендер плейна
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.mesh && this.camera) {
            renderer.render(this.mesh, this.camera);
        }
    }

    /**
     * Высвобождение ресурсов
     * @protected
     */
    protected dispose(): void {
        if (this.material) {
            this.material.dispose();
            this.material = undefined;
        }
        if (this.mesh) {
            this.mesh.geometry.dispose();
            this.mesh = undefined;
        }
    }

    /**
     * Вес сцены - рисуется поверх всего
     */
    public getOrder(): number {
        return -1000;
    }

    /**
     * Установка интенсивности шума
     * @param intensity
     */
    public setIntensity(intensity: number) {
        this.intensity = intensity;
    }

    /**
     * Ресайз - не используется
     * @param width
     * @param height
     * @protected
     */
    protected resize(width: number, height: number): void { }

    /**
     * Приход - не используется
     * @protected
     */
    protected enter(): void { }

    /**
     * Уход - не используется
     * @protected
     */
    protected leave(): void { }

}


/**
 * Хук для включения шума
 * @param manager
 * @param intensity
 */
export function useNoise(manager: GraphicsManager | null = null, intensity: number = 1.0) {
    const sceneRef = useRef<NoiseScene | null>(null);

    useEffect(() => {
        if (!manager) return;

        const scene = new NoiseScene();
        scene.setIntensity(intensity);
        manager.addScene(scene);
        sceneRef.current = scene;

        return () => {
            manager.removeScene(scene);
            scene.disposeScene();
            sceneRef.current = null;
        }
    }, [manager]);

    useEffect(() => {
        if (!sceneRef.current) return;
        sceneRef.current!.setIntensity(intensity);
    }, [sceneRef, intensity]);
}