import styles from './CloseButton.module.scss';

type Props = {
  onClose?: () => void;
  dark?: boolean;
}

export const CloseButton = ({onClose = (() => {}), dark}: Props) => {
  let className = styles.default;
  if (dark) className = styles.dark;

  return <button className={className} onClick={onClose}/>
}