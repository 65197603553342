import {GraphicsScene} from "../GraphicsScene";
import {
    BufferGeometry,
    LineBasicMaterial,
    LineLoop, LoadingManager,
    PerspectiveCamera,
    RepeatWrapping,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";

export const loadingManager = new LoadingManager();

export class PreloaderScene extends GraphicsScene{

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;

    /**
     *  Меш для второго шарика
     * @private
     */
    private spriteSmall: Sprite | null = null;

    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer : WebGLRenderer) {

        //  Инит лоадера
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);
        this.camera.position.set(0.2, -0.2  ,0);

        //Загрузка текстуры точки
        const dotMap = textureLoader.load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap } );


        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 1.6;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка овала
        if (this.ellipse && this.sprite){
            //Установка овала
            this.ellipse.position.set(0.1,0,-6);
            this.ellipse.rotation.set(0, 0, 0);
            this.ellipse.scale.setScalar(1.2);

            // Установка точек
            this.sprite.scale.setScalar(0.05);
            this.sprite.position.set(0, 0, 1.6);

            this.spriteSmall = this.sprite.clone();
            this.spriteSmall.scale.setScalar(0.02);
            this.spriteSmall.position.set(0, 0, -1.6);

            this.ellipse.add(this.sprite, this.spriteSmall);
            this.scene.add(this.ellipse);
        }
    }

    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        // Анимации для шариков
        if (this.ellipse){

            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.rotation.set(0.38, this.ringTurn, 0.5, 'ZXY');
        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }

    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }

    protected dispose(): void {
        this.sprite?.material.dispose();
        this.sprite?.geometry.dispose();
    }




}