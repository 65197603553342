import React, {useContext, useEffect, useRef} from 'react';
import styles from "./EyesBlock.module.scss"
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";
import {EyeScene} from "../../graphics/scenes/EyeScene";
import {useCircle} from "../../graphics/scenes/CircleScene";
import {Color} from "three";

const EyesBlock = () => {

    const blockRef = useRef<HTMLDivElement>(null);
    const circleBlueRef = useRef<HTMLDivElement>(null);
    const circleRedRef = useRef<HTMLDivElement>(null);
    const manager = useContext(ManagerContext);

    useCircle(manager, circleBlueRef, {color: new Color('#6A9CFF'), scale: 8, opacity: 0.3, renderOrder: 20});
    useCircle(manager, circleRedRef, {color: new Color('red'), scale: 4, opacity: 0.05});


    useEffect(() => {
        if(manager){
            if (!manager || !blockRef.current) return;

            const scene = new EyeScene(blockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, blockRef.current])

    return (
        <div className={styles.eyesBlock} id={"eye"} >
            <div className={styles.eyesBlockCircles} >
                <div className={styles.eyesBlockCirclesBlue} ref={circleBlueRef}></div>
                <div className={styles.eyesBlockCirclesRed} ref={circleRedRef}></div>
            </div>
            <div className={styles.eyesBlockScene} ref={blockRef}></div>
            <div className={styles.eyesBlockCircleBlue}></div>
            <div className={styles.eyesBlockCircleRed}></div>
            <h2 className={styles.text}>
                <span> давайте посмотрим </span>какие плюсы есть
                для пользователей
                и&nbsp;бизнеса. Механизмы
                на&nbsp;задворках кода,
                когда вы пользуетесь сервисом
            </h2>
        </div>
    );
};

export default EyesBlock;