import React, {useContext, useEffect, useRef} from 'react';
import styles from "./Footer.module.scss"
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";
import {FooterScene} from "../../graphics/scenes/FooterScene";
import {useCircle} from "../../graphics/scenes/CircleScene";
import rkLogo from "../../../media/images/RkLogo.png"
import tamoLogo from "../../../media/images/TamoLogo.svg"

import {Color} from "three";
import SoundIcon, {SoundProps} from "COMPONENTS/Sound/SoundIcon";

interface FooterProps extends SoundProps{

}

const Footer = ({toggle, playing}: FooterProps) => {
    const blockRef = useRef<HTMLDivElement>(null);
    const circleRef = useRef<HTMLDivElement>(null);
    const manager = useContext(ManagerContext);

    useEffect(() => {
        if(manager){
            if (!manager || !blockRef.current) return;

            const scene = new FooterScene(blockRef.current);
            manager.addScene(scene);


            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, blockRef.current]);

    useCircle(manager, circleRef,  {color: new Color('red'), scale: 7.5,opacity: 0.15});

    return (
        <div className={styles.FooterWrapper} >
            <div className={styles.FooterScene} ref={blockRef}></div>
            <div className={styles.CircleScene} ref={circleRef}></div>
            <div className={styles.sideText}>Кто с нами, пишите</div>
            <div className={styles.FooterWrapperCircle}></div>
            <h1>hello@redcollar.ru</h1>
            <p>помогаем готовиться к дальним полетам <br/>
                и запускаем большие продукты</p>
            <div className={styles.footerMadeBy}>Cделано в
                <a href={'https://redcollar.ru/'} className={styles.footerMadeByRk}><img src={rkLogo} alt="redCollar"/> red collar</a> x  <a href={'https://gotamo.ru/#top'} className={styles.footerMadeByTamo}><img src={tamoLogo} alt="Tamo"/> тамо</a>
                <div className={styles.soundIcon}>
                    <SoundIcon toggle={toggle} playing={playing}/>
                </div>
            </div>
        </div>
    );
};

export default Footer;