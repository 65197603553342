import React from 'react';
import {useLocation} from 'react-router-dom';
import {Content, ContentProps} from 'COMMON/content/Content';
import useHTTP, {normalizeUrl} from 'HOOKS/useHTTP';

export const Page = () => {
  const {pathname, search} = useLocation();
  let action = pathname;
  if (search) action += search;
  // const [response, loading, success, error] = useHTTP<ContentProps>(normalizeUrl(action));
  //
  // if (!response) return <div>Loading...</div>;

  return <Content />
}