import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    DirectionalLight,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping,
    TextureLoader, Vector2, Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";

interface EyeEntry {
    mesh: Mesh,
    position: Vector3,
    timeOffset: number,
    amplitude: number,
    speed: number
}

// TODO  optional: сделать по клику на глаз анимацию


export class EyeScene extends GraphicsScene{


    /**
     * Меш для глаза
     * @private
     */
    private eyeBall: Mesh | null = null;


    /**
     * Меш для века
     * @private
     */
    private eyeLid: Mesh | null = null;


    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Направленный свет
     * @private
     */
    private dirLight: DirectionalLight |null = null

    /**
     * Направленный Свет Красный
     * @private
     */
    private dirLightRed: DirectionalLight | null = null;

    /**
     * Массив глаз
     * @private
     */
    private eyesList: EyeEntry[] = [];

    /**
     * Позиция мыши
     * @private
     */
    private mouse: Vector2 = new Vector2(0, 0);

    private targetMouse: Vector2 = new Vector2(0, 0);

    /**
     * Время с загрузки сцены
     * @private
     */
    private  elapsed: number = 0;

    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/eyeTexture.jpeg');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели глаза
        const loadEyeBall = await loader.loadAsync("./models/pupil.gltf");

        // Загрузка модели века
        const loadEyeLid = await loader.loadAsync("./models/eye.gltf");

        // Установка модели века
        this.eyeLid = loadEyeLid.scene.getObjectByName("eye") as Mesh;

        this.eyeLid.material = new MeshPhongMaterial({ map: texture});

        // Установка модели глаза
        this.eyeBall = loadEyeBall.scene.getObjectByName('eyeball') as Mesh;
        this.eyeBall.position.z= 0.1;
        this.eyeLid.add(this.eyeBall);

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);
        this.camera.zoom = 1.8;
        this.camera.position.set(0, 0, 7);
        this.camera.updateProjectionMatrix();

        // Установка света
        this.light = new AmbientLight("white", 0.6);
        this.dirLight = new DirectionalLight("white", 0.6);
        this.dirLightRed = new DirectionalLight("red", 0.3);

        renderer.compile(this.scene, this.camera);

    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        if (this.eyeLid){
            let amount = 8;

            // Установка позиций глаз
            for (let i = 0; i < amount; i++) {

                const eye = this.eyeLid.clone();

                let distX = 2.6;
                let distY = 2.1;
                const angle = (Math.PI) / 4 * i + Math.PI/10;
                let x = 0;
                let y = 0;

                const adaptY = (value : number) => {
                    if (i <= amount/2 -1){
                        y+= value;
                    }
                    else{
                        y-= value;
                    }
                };

                if (window.innerWidth > 1100 && window.innerWidth < 1330){
                    distX = 2 ;
                    distY = 1.2;
                }
                if (window.innerWidth < 900){
                    distY = 1.2;
                }

                if (window.innerWidth < 540){
                    distX = 1.5 ;
                    distY = 1.2;
                }
                if (window.innerWidth < 350){
                    distX = 1.18 ;
                }

                x = Math.cos(angle) * distX/(1 - (0.001 * Math.random() * 30));
                y = Math.sin(angle) * distY/(1.7 - (0.1 *( Math.random()* 7)));


                if (window.innerWidth > 1100 && window.innerWidth < 1330){
                    adaptY(0.4);
                }

                if (window.innerWidth < 1100){
                    adaptY(0.3);
                }

                if (window.innerWidth < 900){
                    adaptY(0.5);
                }
                
                let scale = (Math.random()*8)/100;
                const position = new Vector3(x,y,-5);
                this.eyesList.push({
                    mesh: eye,
                    position: position,
                    timeOffset: performance.now(),
                    amplitude: Math.random()*10 +40,
                    speed:0.002 + (0.0005 * Math.random()* 3)
                })

                eye.scale.setScalar(0.12 + scale);

                if (window.innerWidth < 700){
                    eye.scale.setScalar(0.11 + scale);
                }

                eye.position.set(x,y,-5);
                this.scene.add(eye);
            }
        }

        // Установка света
        if  (this.light && this.dirLight && this.dirLightRed){

            this.dirLight.position.set(20,40,30);
            this.dirLightRed.position.set(-40,10, -10);
            this.scene.add( this.dirLight, this.dirLightRed, this.light);
        }

        this.onMouseMove = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.onMouseMove, {passive: true});

    }

    /**
     * Евенты на движение мыши
     * @param event
     * @protected
     */
    protected onMouseMove(event : MouseEvent){
            this.targetMouse.set(
                ( event.clientX / window.innerWidth ) * 2 - 1,
                ( event.clientY / window.innerHeight ) * -2 + 1,
            );
    }



    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        this.elapsed = performance.now();
        this.mouse.add(this.targetMouse.clone().sub(this.mouse).multiplyScalar(0.25));

        if(this.eyesList){
            for (let eye of this.eyesList) {
                eye.mesh.rotation.set(-this.mouse.y, this.mouse.x, 0);
                eye.mesh.position.y = eye.position.y + Math.sin( this.elapsed * eye.speed)/eye.amplitude;
            }
        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {
        window.removeEventListener('mousemove', this.onMouseMove);
    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
         this.elapsed = 0;
         this.light?.dispose();
         this.dirLightRed?.dispose();
         this.dirLight?.dispose();
        for (const eye of this.eyesList) {
            eye.mesh.geometry.dispose();
            (eye.mesh.material as MeshPhongMaterial).dispose();
        }
    }




}