import {GraphicsScene} from "../GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    DirectionalLight,
    LineBasicMaterial,
    LineLoop,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "../Banner/PreloaderScene";



export class FeatureScene extends GraphicsScene{


    /**
     * Меш для планшета
     * @private
     */
    private mixingModel: Mesh | null = null;


    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет направленный
     * @private
     */
    private  dirLight : DirectionalLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;
    private ellipseInner: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;
    private spriteInner: Sprite | null = null;

    /**
     * Амплитуда для качания корабля
     * @private
     */
    private swingMark: number = 0;

    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;



    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {


        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  планшета
        const tabletTexture = textureLoader.load('./models/textures/mixingTexture.jpg');
        tabletTexture.flipY = false;
        tabletTexture.wrapS = RepeatWrapping;
        tabletTexture.wrapT = RepeatWrapping;

        // Загрузка текстуры точки
        const dotMap = textureLoader.load( './models/textures/ellipse.png' );

        // Загрузка моделей
        const loadTabletModel = await loader.loadAsync("./models/mixing.gltf");

        //  Установка моделей
        this.mixingModel = loadTabletModel.scene.getObjectByName("mixing") as Mesh;

        // Установка текстур
        this.mixingModel.material = new MeshPhongMaterial({
            map: tabletTexture,
        });

        const dotMaterial = new SpriteMaterial( { map: dotMap } );


        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);


        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 2.7;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );
        this.ellipseInner = this.ellipse.clone();

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );
        this.spriteInner = this.sprite.clone();

        // Инит амплитуды
        this.swingMark = 0;

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка моделей
        if(this.mixingModel){
            this.mixingModel.position.set(0.1,-0.6,-5);
            this.mixingModel.rotation.z = Math.PI/4;
            this.mixingModel.rotation.y = Math.PI;
            this.mixingModel.scale.setScalar(1.8);
            this.scene.add(this.mixingModel);
        }

        // Установка светильников
        if(this.dirLight && this.mixingModel){
            this.dirLight.target = this.mixingModel;
        }

        // Установка овала
        if (this.ellipse && this.sprite && this.spriteInner && this.ellipseInner){

            //Установка овала
            this.ellipse.position.set(0.1,-0.3,-6);
            this.ellipse.rotation.set(-0.1,0,-6);

            //Установка овала внутреннего
            this.ellipseInner.position.set(0.21,-0.7,-6);
            this.ellipseInner.rotation.set(0.1,0,-6);

            // Установка точек
            this.sprite.scale.setScalar(0.05);
            this.sprite.position.set(0, 0, 2.7);
            this.spriteInner.scale.setScalar(0.05);
            this.spriteInner.position. set(0,0,-2.7);

            this.ellipse.add(this.sprite);
            this.ellipseInner.add(this.spriteInner);
            this.scene.add(this.ellipseInner);
            this.scene.add(this.ellipse);
        }

        // Установака света
        if (this.light){
            this.light.intensity =1.1;
            this.scene.add(this.light);
        }
        this.scene.position.y = 0.5
    }


    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {
        this.swingMark = (this.swingMark + 0.025 * delta) % (Math.PI * 2);

        // Анимации для мешей
        if (this.mixingModel ) {
            this.mixingModel.position.y += Math.sin(this.swingMark) * 0.001;
            this.mixingModel.rotation.y += 0.015 * delta;
        }

        // Анимации для шариков
        if (this.ellipse && this.ellipseInner){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);

            this.ellipse.rotation.set(Math.PI/180 * 15, this.ringTurn, -0.4, 'ZXY');
            this.ellipseInner.rotation.set(Math.PI/180 * -15, this.ringTurn, 0.4, 'ZXY');

        }

    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {

        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }

    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
        this.dirLight?.dispose();
        this.sprite?.material.dispose();
        this.light?.dispose();
        this.mixingModel?.geometry.dispose();
        (this.mixingModel?.material as MeshPhongMaterial).dispose();
        this.ellipse?.geometry.dispose();
        this.ellipseInner?.geometry.dispose();
    }




}