import React, {useContext, useRef} from 'react';
import styles from './Details.module.scss'
import {useCircle} from "../../graphics/scenes/CircleScene";
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";
import {Color} from "three";
import presentationPath from "../../../media/pdf/tamo-development.pdf"
import ButtonBall from "COMPONENTS/Banner/ButtonBall";

const Details = () => {
    const manager = useContext(ManagerContext);

    const redCircleRef = useRef<HTMLDivElement>(null);
    useCircle(manager,redCircleRef, {color: new Color('red'), scale: 6.5, opacity: 0.1} );
    const greenCircleRef = useRef<HTMLDivElement>(null);
    useCircle(manager,greenCircleRef, {color: new Color(0xADFF00), scale: 6.5, opacity: 0.1} );

    return (
        <div className={styles.details} id={'details'}>
            <div ref={redCircleRef} className={styles.detailsCirclesRed}></div>
            <div ref={greenCircleRef} className={styles.detailsCirclesGreen}></div>
            <div className={styles.detailsContent}>
                <h2 className={styles.detailsContentTitle}>посмотрите детали в&nbsp;полном&nbsp;{window.innerWidth < 640 ? `` : <br/>}кейсе&nbsp;{window.innerWidth < 640 ? <br/>: ''}разработки</h2>
                <a href={presentationPath} target={"_blank"} type={'button'}  className={styles.button}>
                    <ButtonBall text={'открыть pdf'} long={true}/>
                </a>
            </div>
        </div>
    );
};

export default Details;