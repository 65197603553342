import styles from './Picture.module.scss';

import React from 'react';
import useClassName from 'HOOKS/useClassName';

type PictureItem = {
  src: string;
  [key: string]: string;
}
export type PictureType = {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
  sources?: {
    desktop: PictureItem;
    tablet?: PictureItem;
    mobile?: PictureItem;
  }
}

export type PictureProps = {
  picture: PictureType;
  className?: string;
}


export const Picture = ({picture, className}: PictureProps) => {
  className = useClassName(styles.item, className);

  return <picture className={className}>
    {/*TODO: Дописать srcset*/}
    <img src={picture.src} alt={picture.alt}/>
  </picture>
}