import {Lang} from 'TYPES/State';


const translations = {
  ru: {
    title: 'Привет, Мир!',
    text: 'Просто текст'
  },
}

export const languages = Object.keys(translations) as Lang[];
export const defaultLang = 'ru';
export default translations;
