import React from 'react';
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import {basename} from 'COMMON/router';
import {languages, defaultLang} from 'COMMON/translations';
import {LangChecker} from 'COMMON/Lang/Lang';
import ScrollToTop from './ui/scroll-top/ScrollToTop';
import {NoticeWrapper} from './ui/notice/Notice';
import {Page} from 'COMPONENTS/Page';
import Wrapper from "COMPONENTS/Wrapper/Wrapper";



type AppProps = {
}

// класс потому, что react-refresh-webpack-plugin
// не работает в руте с чистой функцией

export default class App extends React.Component<AppProps> {
  render() {
    const routes = <Routes>
      <Route path='/*' element={<Page/>}/>
    </Routes>

    let returned;

    // TODO: Языки(пока скрыто)
    if (languages.length > 1) {
      returned = <Routes>
        {
          languages.map((lang) => {
            return <Route key={lang} path={`${lang}/*`} element={<>
              <LangChecker lang={lang}/>
              {routes}
            </>}/>
          })
        }

        <Route path='*' element={<Navigate to={defaultLang}/>}/>
      </Routes>
    } else {
      returned = routes;
    }

    return (
      <BrowserRouter basename={basename}>
        <NoticeWrapper/>
        <ScrollToTop/>
        <Wrapper/>
        {returned}
      </BrowserRouter>
    );
  }
}