import React, {useContext, useEffect, useRef} from 'react';
import styles from "./TitleBlock.module.scss"
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";
import {TitleScene} from "../../graphics/scenes/TitleScene";
import {useCircle} from "../../graphics/scenes/CircleScene";
import {Color} from "three";

const TitleBlock = () => {

    const blockRef = useRef<HTMLDivElement>(null);
    const manager = useContext(ManagerContext);

    useEffect(() => {
        if(manager){
            if (!manager || !blockRef.current) return;

            const scene = new TitleScene(blockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, blockRef.current])

    const circleRef = useRef<HTMLDivElement>(null);
    useCircle(manager, circleRef, {color: new Color("red"), scale: 8});


    return (
        <div className={styles.TitleBlock} id={'title'}>
            <div className={styles.TitleBlockScene} ref={blockRef}></div>
            <div className={styles.circle} ref={circleRef}></div>
            <div className={styles.TitleBlockTitle}>
                <h1>как работает <br/>
                    backend</h1>
                <p>что происходит по ту сторону <br/> сервисов </p>
            </div>

                <div className={styles.Content} >
                    <div className={styles.ContentText}>
                        <div className={styles.textPrimary}>большой сервис, <br/> как космическая станция: <br/> состоит из компонентов <br/> и летает на высоких скоростях</div>
                        <p className={styles.textSecondary}>Расскажем, что под капотом высоконагруженных продуктов на примере архитектуры таможенного сервиса тамо. Выбрали микросервисную архитектуру&nbsp;—&nbsp;это компоненты системы, которые можно менять и&nbsp;управлять ими на&nbsp;лету.</p>
                    </div>
                </div>
        </div>
    );
};

export default TitleBlock;