import {GraphicsScene} from "./GraphicsScene";
import {
    Color,
    Mesh, MeshBasicMaterial,
    PerspectiveCamera, PlaneBufferGeometry,
    RepeatWrapping, Texture,
    TextureLoader,
    WebGLRenderer
} from "three";
import {GraphicsManager} from "../GraphicsManager";
import {RefObject, useEffect} from "react";
import {loadingManager} from "./Banner/PreloaderScene";

export interface CircleProps{
    color: Color,
    scale: number,
    opacity?:number,
    renderOrder?: number

}

export class CircleScene extends GraphicsScene{

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Меш для круга
     * @private
     */
    private circlePlane: Mesh | null = null;

    /**
     * Материал для круга
     * @private
     */
    private  circleMaterial? : MeshBasicMaterial;

    /**
     * Текстура для круга
     * @private
     */
    private texture: Texture | null = null;

    /**
     * Скейл для круга
     * @private
     */
    private  circleScale: number = 1;

    /**
     * Цвет для круга
     * @private
     */
    private color?: Color | null = null;

    /**
     * Прозрачность круга
     * @private
     */
    private opacity?: number = 0.2;

    /**
     * Слой для круга
     * @private
     */
    private renderOrder?: number = 10;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer ) {
        // Загрузка текстуры  корабля
        this.texture = new TextureLoader(loadingManager).load('./models/textures/circleTexture.png');
        this.texture.flipY = false;
        this.texture.wrapS = RepeatWrapping;
        this.texture.wrapT = RepeatWrapping;

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);
        this.camera.frustumCulled = false;

        this.circleMaterial =  new MeshBasicMaterial({
            alphaMap: this.texture,
            opacity: 0.15,
            transparent: true,
        })

        // Установка круга
        this.circlePlane = new Mesh(new PlaneBufferGeometry(),this.circleMaterial);

        renderer.compile(this.scene, this.camera);
    }

    /**
     * Настройка круга
     * @param circle
     */
    public setCircle(circle: CircleProps){
        this.circleScale = circle.scale;
        this.color = circle.color;
        this.opacity = circle.opacity;
        this.renderOrder = circle.renderOrder;

    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {
        if (this.circlePlane && this.color){
            this.circlePlane.scale.setScalar(this.circleScale);
            (this.circlePlane.material as MeshBasicMaterial).color = this.color;
            this.circlePlane.position.set(0,0, -5);
            this.scene.add(this.circlePlane);
        }
        if (this.opacity && this.circlePlane){
            (this.circlePlane.material as MeshBasicMaterial).opacity = this.opacity;
        }
        if(this.camera){
            this.camera.position.z = 3;
            this.scene.add(this.camera);

        }
    }


    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

    }

    /**
     * Вес сцены
     */
    public getOrder(): number {
        return this.renderOrder ? this.renderOrder : 10;
    }

    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }

    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }

    protected dispose(): void {
        if (this.circlePlane){
            this.circlePlane.geometry.dispose();
        }
        if (this.circleMaterial){
            this.circleMaterial.dispose();
            this.circleMaterial = undefined;
        }
    }
}

/**
 * Хук для включения шума
 * @param manager
 * @param circleRef
 * @param circle
 */
export function useCircle(manager: GraphicsManager | null = null, circleRef : RefObject<HTMLDivElement>, circle : CircleProps) {

    useEffect(() => {
        if(manager){
            if (!manager || !circleRef.current) return;

            const scene = new CircleScene(circleRef.current);
            scene.setCircle(circle);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, circleRef.current])
}