import React, {useEffect} from 'react';
import {Lang} from 'TYPES/State';
import {useDispatch} from 'STORE/store';
import {setLang} from 'STORE/slices/Options';

type Props = {
  lang: Lang;
};

export const LangChecker = ({lang}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLang(lang));
  }, [lang]);

  return null;
}