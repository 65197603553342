import React, {useEffect, useRef, useState} from 'react';
import styles from './Banner.module.scss'
import {useCircle} from "../../graphics/scenes/CircleScene";
import {Color} from "three";
import {useGraphicsManager} from "../../graphics/hooks/useGraphicsManager";
import clsx from "clsx";
import {loadingManager, PreloaderScene} from "../../graphics/scenes/Banner/PreloaderScene";
import ButtonBall from "COMPONENTS/Banner/ButtonBall";



interface  BannerProps{
    toggleMusic?:   boolean | (() => void);
    setIsBanner: (value: boolean) => void;
}

const Banner = ({toggleMusic, setIsBanner}: BannerProps) => {
    const bannerRef = useRef<HTMLDivElement>(null);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [preload, setPreload] = useState<number>(0);

    const {manager, canvas} = useGraphicsManager({
        className: styles.canvas,
    });

    // Обновление значения загрузки
    loadingManager.onProgress = function ( url, itemsLoaded, itemsAmount ) {
        setTimeout(() => {
            const step = 100 / itemsAmount;
            setPreload(  Math.floor(itemsLoaded * step) );
        },500)
    };

    // Установка флага загрузки страницы
    loadingManager.onLoad = function () {
        setTimeout(() => {
            setIsLoad(true);
        },1000)
    };

    useEffect(() => {
        if (manager) {
            if (!manager || !bannerRef.current) return;

            const scene = new PreloaderScene(bannerRef.current);
            manager.addScene(scene);

            if (isLoad){
                manager.removeScene(scene);
                scene.disposeScene();
            }

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    }, [manager, bannerRef.current, isLoad])

    const circleRef = useRef<HTMLDivElement>(null);
    useCircle(manager, circleRef,{color: new Color("red"), scale: 8, opacity:0.2});

    const closeBanner = () => {
        // @ts-ignore
        toggleMusic();
        setIsBanner(false);
    }



    return (
        <div className={styles.banner}>
            {canvas}
            <div className={clsx( styles.preload, isLoad && styles.active)} ref={bannerRef}>
                <div>{preload}%</div>
            </div>
            <div className={clsx(styles.circle, isLoad && styles.active)} ref={circleRef} ></div>
            <div className={clsx(styles.bannerContent, isLoad && styles.active)}>
                <div className={styles.bannerContentIcon}>
                    <svg width="79" height="68" viewBox="0 0 79 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M73.1383 36.2984C73.1383 18.1913 57.8951 3.5127 39.0916 3.5127C20.2881 3.5127 5.04492 18.1913 5.04492 36.2984" stroke="#0D0C0C" strokeWidth="5.04395"/>
                        <rect y="40.3057" width="9.19779" height="27.5934" fill="#0D0C0C"/>
                        <rect x="68.9832" y="40.3057" width="9.19779" height="27.5934" fill="#0D0C0C"/>
                    </svg>
                </div>
                <div className={clsx( styles.bannerContentText)}>
                    <h2>
                        для большего погружения на&nbsp;сайте используется звук
                    </h2>
                </div>
                <ButtonBall text={'начать'} onClick={closeBanner}/>
            </div>
        </div>
    );
};

export default Banner;