import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    DirectionalLight,
    LineBasicMaterial,
    DoubleSide,
    LineLoop,
    Vector2,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer,
    MeshBasicMaterial,
    AdditiveBlending,
    PlaneBufferGeometry
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";



export class SideTextScene extends GraphicsScene{


    /**
     * Меш для планшета
     * @private
     */
    private tabletModel: Mesh | null = null;

    /**
     * Меш для пульта
     * @private
     */
    private remoteModel: Mesh | null = null;

    /**
     * Меш для эквалайзера
     * @private
     */
    private mixingModel: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет направленный
     * @private
     */
    private  dirLight : DirectionalLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для внутреннего овала
     * @private
     */
    private ellipseInner: LineLoop | null = null;

    /**
     * Меш для круга сзади
     * @private
     */
    private circlePlane: Mesh | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;

    /**
     *  Меш для внутреннего шарика
     * @private
     */
    private spriteInner: Sprite | null = null;

    /**
     * Амплитуда для качания корабля
     * @private
     */
    private swingMark: number = 0;


    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;

    /**
     * Позиция мыши
     * @private
     */
    private mouse: Vector2 = new Vector2(0, 0);

    private targetMouse: Vector2 = new Vector2(0, 0);

    /**
     * Позиция скролла
     * @private
     */
    private scrollPosition :number = -1;


    private modelIndex: number = -1;

    private nextModelIndex: number = 0;

    private spinCounter: number = 1;

    private scale: number = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {


        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        const [
            tabletTexture,
            remoteTexture,
            mixingTexture,
            circleTexture,
            dotMap
        ] = await Promise.all([
            './models/textures/multipassTexture.jpg',
            './models/textures/pultTexture.jpg',
            './models/textures/mixingTexture.jpg',
            './models/textures/circleTexture.png',
            './models/textures/ellipse.png',
        ].map(async (path) => {
            const tex = await textureLoader.loadAsync(path);
            tex.flipY = false;
            tex.wrapS = RepeatWrapping;
            tex.wrapT = RepeatWrapping;
            return tex;
        }));

        // Загрузка моделей
        const loadTabletModel = await loader.loadAsync("./models/multipass.gltf");
        const loadRemoteModel = await loader.loadAsync("./models/pult.gltf");
        const loadMixingModel = await loader.loadAsync("./models/mixing.gltf");


        //  Установка моделей
        this.tabletModel = loadTabletModel.scene.getObjectByName("multipass") as Mesh;
        this.remoteModel = loadRemoteModel.scene.getObjectByName("pult") as Mesh;
        this.mixingModel = loadMixingModel.scene.getObjectByName("mixing") as Mesh;
        this.circlePlane = new Mesh(new PlaneBufferGeometry(), new MeshBasicMaterial({
            alphaMap: circleTexture,
            transparent: true,
            blending: AdditiveBlending,
            color: 0xff0000,
            opacity: 0.25
        }));


        // Установка текстур
        this.tabletModel.material = new MeshPhongMaterial({
            map: tabletTexture,
        });
        this.remoteModel.material = new MeshPhongMaterial({
            map: remoteTexture,
        });
        this.mixingModel.material = new MeshPhongMaterial({
            map: mixingTexture,
            side: DoubleSide
        });
        const dotMaterial = new SpriteMaterial({
            map: dotMap
        });

        // Установка камеры
        this.camera = new PerspectiveCamera(30, 1, 0.01, 100);
        this.camera.position.set(0,0,3);

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 2.2;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );
        this.ellipseInner = this.ellipse.clone();

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );
        this.spriteInner = this.sprite.clone();

        // Инит амплитуды
        this.swingMark = 0;


        this.onScroll = this.onScroll.bind(this);
        const sideTextBlock = document.getElementById("other-list");
        window.addEventListener('scroll', ()=> {this.onScroll(sideTextBlock!.getBoundingClientRect().top - 550)},  {passive: true});

        this.onMouseMove = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.onMouseMove, {passive: true});

        renderer.compile(this.scene, this.camera);
    }

    /**
     * Позиция скролла
     * @param scrollPos
     * @protected
     */
    protected onScroll(scrollPos: number){
        if (scrollPos < 0){
            this.scrollPosition = -scrollPos;
        }
    }

    /**
     * Евенты на движение мыши
     * @param event
     * @protected
     */
    protected onMouseMove(event : MouseEvent){
        this.targetMouse.set(
            ( event.clientX / window.innerWidth ) * 2 - 1,
            ( event.clientY / window.innerHeight ) * -2 + 1,
        );
    }



    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка моделей
        if(this.tabletModel){
            this.tabletModel.position.set(0.1,-0.6,-5);
            this.tabletModel.rotation.z = Math.PI/4;
            this.tabletModel.rotation.y = Math.PI;
            this.tabletModel.visible = false;
            this.scene.add(this.tabletModel);
        }

        if (this.remoteModel){
            this.remoteModel.position.set(0.1,-0.6,-5);
            this.remoteModel.rotation.z = Math.PI/4;
            this.remoteModel.rotation.y = Math.PI;
            this.remoteModel.visible = false;
            this.scene.add(this.remoteModel);
        }

        if (this.mixingModel){
            this.mixingModel.position.set(0.1,-0.6,-5);
            this.mixingModel.rotation.z = Math.PI/4;
            this.mixingModel.rotation.y = Math.PI;
            this.mixingModel.visible = false;
            this.scene.add(this.mixingModel);
        }

        // Установка светильников
        if(this.dirLight && this.tabletModel){
            this.dirLight.target = this.tabletModel;
        }

        // Установка овала
        if (this.ellipse && this.sprite && this.spriteInner && this.ellipseInner){

            //Установка овала
            this.ellipse.position.set(0.1,-0.3,-6);
            this.ellipse.rotation.set(-0.1,0,-6);

            //Установка овала внутреннего
            this.ellipseInner.position.set(0.21,-0.7,-6);
            this.ellipseInner.rotation.set(0.1,0,-6);

            // Установка точек
            this.sprite.scale.setScalar(0.03);
            this.sprite.position.set(0, 0, 2.2);
            this.spriteInner.scale.setScalar(0.03);
            this.spriteInner.position. set(0,0,-2.2);

            this.ellipse.add(this.sprite);
            this.ellipseInner.add(this.spriteInner);
            this.scene.add(this.ellipseInner);
            this.scene.add(this.ellipse);
        }

        // Установка круга
        if (this.circlePlane){
            this.circlePlane.position.set(0.1,-0.6,-10);
            this.circlePlane.scale.setScalar(9);
            this.scene.add(this.circlePlane);
        }

        // Установака света
        if (this.light){
            this.light.intensity =1.1;
            this.scene.add(this.light);
        }
        this.scene.position.y = 0.5
    }


    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        this.mouse.add(this.targetMouse.clone().sub(this.mouse).multiplyScalar(0.1));
        this.swingMark = (this.swingMark + 0.025 * delta) % (Math.PI * 2);


        // Анимации для мешей
        if (this.tabletModel && this.mixingModel && this.remoteModel){
            this.tabletModel.position.y += Math.sin(this.swingMark) *0.001;
            this.tabletModel.rotation.y += 0.015 + this.spinCounter * 0.3 * delta;

            this.mixingModel.position.y += Math.sin(this.swingMark) *0.001;
            this.mixingModel.rotation.y += 0.015 + this.spinCounter * 0.3 * delta;

            this.remoteModel.position.y += Math.sin(this.swingMark) *0.001;
            this.remoteModel.rotation.y +=0.015 + this.spinCounter * 0.3 * delta;
        }

        // Cмена модели
        let targetIdx = 0;
        if (this.scrollPosition > 1000 && this.scrollPosition < 1850 ){
            targetIdx = 1;
        } else if (this.scrollPosition > 1850) {
            targetIdx = 2;
        }

        if (this.spinCounter === 0 && this.modelIndex !== targetIdx) {
            this.nextModelIndex = targetIdx;
            this.spinCounter = 0;
        }

        if (this.nextModelIndex !== -1) {
            this.spinCounter = Math.min(this.spinCounter + 0.03 * delta, 1.0);
            if (this.spinCounter === 1) {
                this.modelIndex = this.nextModelIndex;
                this.nextModelIndex = -1;

                this.tabletModel!.visible = this.modelIndex === 0;
                this.remoteModel!.visible = this.modelIndex === 1;
                this.mixingModel!.visible = this.modelIndex === 2;
            }
        } else {
            this.spinCounter = Math.max(this.spinCounter - 0.03 * delta, 0);
        }
        // ---

        // Анимации для шариков
        if (this.ellipse && this.ellipseInner){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.scale.setScalar(Math.min(1, -(this.scale - performance.now())/1000));

            this.ellipse.rotation.set(Math.PI/180 * 15, this.ringTurn, -0.4, 'ZXY');
            this.ellipseInner.rotation.set(Math.PI/180 * -15, this.ringTurn, 0.4, 'ZXY');

        }

    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }

    }


    protected leave(): void {
        window.removeEventListener('mousemove', this.onMouseMove);
    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
        this.dirLight?.dispose();
        this.sprite?.material.dispose();
        this.sprite?.geometry.dispose();
        this.light?.dispose();
        this.circlePlane?.geometry.dispose();
        this.mixingModel?.geometry.dispose();
        this.remoteModel?.geometry.dispose();
        this.tabletModel?.geometry.dispose();
        (this.circlePlane?.material as MeshBasicMaterial).dispose();
        (this.mixingModel?.material as MeshPhongMaterial).dispose();
        (this.remoteModel?.material as MeshPhongMaterial).dispose();
        (this.tabletModel?.material as MeshPhongMaterial).dispose();
    }




}