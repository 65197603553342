import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    Color,
    DirectionalLight,
    LineBasicMaterial,
    LineLoop,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping, SpotLight,
    Sprite,
    SpriteMaterial,
    TextureLoader, Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";



export class FooterScene extends GraphicsScene{


    /**
     * Меш для корабля
     * @private
     */
    private shipModel: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет для бликов
     * @private
     */
    private  dirLight : DirectionalLight | null = null;
    private    spotLight : SpotLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null

    /**
     * Амплитуда для качания корабля
     * @private
     */
    private swingMark: number = 0

    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;

    /**
     * Позиция мыши
     * @private
     */
    private mouse: Vector2 = new Vector2(0, 0);

    private targetMouse: Vector2 = new Vector2(0, 0);



    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load( renderer: WebGLRenderer) {

        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели корабля
        const loadModel = await loader.loadAsync("./models/station.glb");

        //  Установка модели корабля
        this.shipModel = loadModel.scene.getObjectByName("station") as Mesh;

        this.shipModel.material = new MeshPhongMaterial({
            map: texture,
            color: "#8AA1FF",
            shininess: 50
        });

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);
        this.camera.zoom = 0.85;

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();
        this.spotLight = new SpotLight();


        //Загрузка текстуры точки
        const dotMap = new TextureLoader().load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap } );

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 2.3;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );

        // Инит амплитуды
        this.swingMark = 0;

        this.onMouseMove = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.onMouseMove, {passive: true});

        renderer.compile(this.scene, this.camera);

    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipModel && this.ellipse ){

            this.shipModel.position.set(0.2,0.2,-2.3);
            this.shipModel.rotation.set(Math.PI / 180 * 30,Math.PI / 180 * -110 ,Math.PI / 180 * 10);
            this.shipModel.scale.setScalar(0.6);
            this.shipModel.renderOrder = 2;

            if (window.innerWidth < 500){
                this.ellipse.scale.setScalar(0.8);
                this.shipModel.scale.setScalar(1.1);
            }

            this.ellipse.add(this.shipModel);
        }

        // Установка светильников
        if(this.dirLight && this.shipModel &&  this.spotLight){
            this.dirLight.intensity = 0.5;
            this.dirLight.target = this.shipModel;
            this.dirLight.position.set(-15,20,10);

            this.spotLight.color = new Color("#E786E2");
            this.spotLight.target = this.shipModel;
            this.spotLight.angle = Math.PI/10;
            this.spotLight.intensity =0.4;
            this.spotLight.position.set(3,-1,3);

            this.scene.add(this.dirLight, this.spotLight);

        }

        // Установка овала
        if (this.ellipse && this.sprite){
            //Установка овала
            this.ellipse.position.set(-0.35,0,-6);
            this.ellipse.rotation.set(0, 0, 0);

            // Установка точек
            this.sprite.scale.setScalar(0.03);
            this.sprite.position.set(0, 0, 2.3);

            if (window.innerWidth < 500){
                this.sprite.scale.setScalar(0.07);
            }

            this.ellipse.add(this.sprite);
            this.scene.add(this.ellipse);
        }

        // Установака света
        if (this.light){
            this.light.intensity = 0.95;
            this.scene.add(this.light);
        }
    }

    /**
     * Евенты на движение мыши
     * @param event
     * @protected
     */
    protected onMouseMove(event : MouseEvent){
        this.targetMouse.set(
            ( event.clientX / window.innerWidth ) * 2 - 1,
            ( event.clientY / window.innerHeight ) * -2 + 1,
        );
    }


    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {
        this.mouse.add(this.targetMouse.clone().sub(this.mouse).multiplyScalar(0.1));
        this.swingMark = (this.swingMark + 0.025 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipModel){
            this.shipModel.position.y += Math.sin(this.swingMark) *0.001;
            this.shipModel.rotation.set((Math.PI / 180 * 30) - this.mouse.x/5 ,(Math.PI / 180 * -110) - this.mouse.x/5,Math.PI / 180 * 10);

            if (window.innerWidth > 1440 && window.innerHeight < 800){
                this.shipModel.rotation.set((Math.PI / 180 * 30) - this.mouse.x/5 ,(Math.PI / 180 * -110) - this.mouse.x/5,Math.PI / 180 * -3);
            }

            if (window.innerWidth < 500){
                this.shipModel.rotation.set((Math.PI / 180 * 30) - this.mouse.x/5 ,(Math.PI / 180 * -110) - this.mouse.x/5,Math.PI / 180 * -3);
                this.shipModel.scale.setScalar(1.1);
            }
        }

        // Анимации для шариков
        if (this.ellipse){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.rotation.set(0.3, this.mouse.x/2 - 2, -0.4, 'ZXY');

            if (window.innerWidth > 1440 && window.innerHeight < 800){
                this.ellipse.rotation.set(0.25, this.mouse.x/2 - 2, -0.45, 'ZXY');
            }

            if (window.innerWidth < 500){
                this.ellipse.rotation.set(0.3, this.mouse.x/2 - 2, -0.6, 'ZXY');

            }
        }

    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
        if (this.shipModel)
        {
            if (window.innerWidth < 450){
                this.shipModel.scale.setScalar(0.8);
            }
            else {
                this.shipModel.scale.setScalar(0.6);
            }
        }
        
    }



    protected dispose(): void {
        this.dirLight?.dispose();
        this.sprite?.material.dispose();
        this.spotLight?.dispose();
        this.light?.dispose();
        this.shipModel?.geometry.dispose();
        (this.shipModel?.material as MeshPhongMaterial).dispose();
        this.ellipse?.geometry.dispose();
        this.sprite?.geometry.dispose();
    }




}