import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight, BackSide, DirectionalLight, DoubleSide,
    LineBasicMaterial, MathUtils,
    Mesh, MeshBasicMaterial, MeshLambertMaterial, OrthographicCamera,
    RepeatWrapping,
    TextureLoader,
    WebGLRenderer
} from "three";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";

export class GateScene extends GraphicsScene{


    /**
     * Меш для линейной модели
     * @private
     */
    private shipLineModel: Mesh | null = null;

    /**
     * Меш для коня
     * @private
     */
    private horseModel: Mesh | null = null;

    /**
     * Меш для крыльев линейной модели
     * @private
     */
    private shipLineWings: Mesh | null = null;

    /**
     * Меш для люз
     * @private
     */
    private portholeBlack: Mesh | null = null;

    /**
     * Меш для люз
     * @private
     */
    private portholeGreen: Mesh | null = null;


    /**
     * Меш для шлюза линейного
     * @private
     */
    private  portholeLine: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: OrthographicCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет для шдюзов
     * @private
     */
    private dirLight: DirectionalLight | null = null;

    /**
     * Позиция для вертикальной анимации подарка
     * @private
     */
    private swingPosition: number = 0;

    /**
     * Позиция скролла
     * @private
     */
    private scrollPosition: number = 0;

    /**
     * Дельта для плавного скролла
     * @private
     */
    private time: number = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const objLoader = new OBJLoader(loadingManager);
        const gltfLoader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/portholeTexture.jpg');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка тектсуры коня
        const horseTexture = textureLoader.load('./models/textures/horseTexture.png')
        horseTexture.flipY = false;
        horseTexture.wrapS = RepeatWrapping;
        horseTexture.wrapT = RepeatWrapping;

        // Загрузка линейной модели корабля
        const loadLineModel = await objLoader.loadAsync("./models/gateLine.obj");

        //Загрузка коня
        const horse = await gltfLoader.loadAsync('./models/Horse.gltf');
        this.horseModel = horse.scene.getObjectByName('horse') as Mesh;

        //Установка материала для коня
        this.horseModel.material = new MeshBasicMaterial({map: horseTexture, side: BackSide});

        //Загрузка моделей для линейного корабля
        this.shipLineModel = loadLineModel.getObjectByName('line_Plane.001') as Mesh;
        this.shipLineWings = loadLineModel.getObjectByName("plane_Cube.010") as Mesh;
        this.portholeLine = loadLineModel.getObjectByName("portholeLine_Plane.002") as Mesh;
        this.portholeBlack = loadLineModel.getObjectByName("portholeFill_Cylinder.007") as Mesh;

        // Установка материалов для корабля
        this.shipLineModel.material = new LineBasicMaterial({side: BackSide,  color: 0x0, transparent: true});
        this.shipLineWings.material = new MeshBasicMaterial({side: DoubleSide, color: 0x0, transparent: true});
        this.portholeLine.material = new LineBasicMaterial({color: 0x0, transparent: true});
        this.portholeBlack.material = new MeshBasicMaterial({color: 0x0,transparent: true, opacity: 0});
        this.portholeGreen = this.portholeBlack.clone();
        this.portholeGreen.material = new MeshLambertMaterial({color: "#c4ff00",transparent: true, opacity: 0});
        this.portholeBlack.renderOrder = 1;
        this.portholeGreen.renderOrder = 2;


        // Установка камеры
        this.camera = new OrthographicCamera(-1, 1, 0, 1, -1, 100);
        this.camera.zoom = 0.9;

        // Установка света
        this.light = new AmbientLight(0xD3E2FF, 1);
        this.dirLight = new DirectionalLight(0xD3E2FF, 1);

        let scrollDelay = 400;
        if(window.innerWidth > 1000){
            scrollDelay = 400;
        }
        if(window.innerWidth < 1000){
            scrollDelay = 700;
        }
        if(window.innerWidth < 600){
            scrollDelay = 900;
        }

        this.onScroll.bind(this.onScroll);
        window.addEventListener('scroll', () => this.onScroll(this.element!.getBoundingClientRect().top - scrollDelay),{passive: true});

        renderer.compile(this.scene, this.camera);

    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipLineModel && this.shipLineWings && this.portholeLine && this.portholeBlack && this.portholeGreen){
            this.shipLineModel.position.set(1.12, 0.45,-5);
            this.shipLineModel.rotation.set(Math.PI/180 * 148.5, Math.PI/180 * 47.2, Math.PI/180 * -5);
            this.shipLineModel.scale.setScalar(0.23);
            if (window.innerWidth < 600){
                this.shipLineModel.scale.setScalar(0.28);
            }

            this.shipLineModel.add(this.shipLineWings, this.portholeLine, this.portholeBlack, this.portholeGreen);
            this.scene.add(this.shipLineModel);
        }

        // Установка света
        if(this.light && this.dirLight && this.shipLineModel){
            this.light.intensity = 0.85;

            this.dirLight.target = this.shipLineModel;
            this.dirLight.position.set(2,-3,3);
            this.dirLight.intensity = 0.5;

            this.shipLineModel.add(this.dirLight);
            this.scene.add(this.light)
        }

        if (this.horseModel){
            this.horseModel.scale.setScalar(0.007);
            this.horseModel.position.set(0.3,0.95,-10);
            this.horseModel.rotation.set(Math.PI,Math.PI/2,0);

            this.scene.add(this.horseModel);
        }

        this.swingPosition = 0;
    }

    /**
     * Управление позиции сцены на верстке
     * @param scrollPos
     */
    public onScroll(scrollPos: number){
        if (scrollPos < 0){
            this.scrollPosition = -scrollPos/100;
        }
    }



    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        this.time = MathUtils.damp(this.time, this.scrollPosition, 0.01, delta);

        let smooth = this.time/4;

        if (window.innerWidth < 1023){
            smooth = 1;
        }

        // Дефолтный рендерОрдер
        if (  this.portholeGreen && this.portholeBlack && this.portholeLine){
            this.portholeLine.renderOrder = -1;
            this.portholeBlack.renderOrder = 1;
            this.portholeGreen.renderOrder = 2;

            (this.portholeLine.material as LineBasicMaterial).opacity = 1;
            (this.portholeBlack.material as MeshBasicMaterial).opacity = 0;
            (this.portholeGreen.material as LineBasicMaterial).opacity = 0;
        }

        //Качание корабля
        this.swingPosition = (this.swingPosition + 0.03 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipLineModel && this.shipLineWings){
            const xMax = 1 - this.scrollPosition * smooth/10;
            const yMax = (this.scrollPosition * smooth + 1)/18;

            this.shipLineModel.position.y = Math.sin(this.swingPosition) * 0.005 + (Math.min(0.55, yMax ));
            this.shipLineModel.position.x = (Math.max(0.1, xMax));
        }

        // Анимация шлюзов
        if (this.portholeBlack && this.portholeLine && this.portholeGreen){

            if (this.scrollPosition > 4){
                (this.portholeLine.material as LineBasicMaterial).opacity = Math.max(0,4.5 - this.scrollPosition*0.98);
                (this.portholeBlack.material as MeshBasicMaterial).opacity = Math.min(1, -4 + this.scrollPosition*0.98);
            }

            if(this.scrollPosition > 4.2){
                this.portholeBlack.renderOrder= -1;
            }

            if (this.scrollPosition > 5){

                this.portholeGreen.renderOrder= -2;
                (this.portholeBlack.material as LineBasicMaterial).opacity = Math.max(0,5.5 - this.scrollPosition*0.98);
                (this.portholeGreen.material as LineBasicMaterial).opacity = Math.min(1, -5 + this.scrollPosition*0.98);

            }
        }

        //Появление корабля
        if(this.shipLineModel && this.shipLineWings && this.portholeLine){
            (this.shipLineModel.material as LineBasicMaterial).opacity = Math.min(1,this.scrollPosition - 3.8);
            (this.shipLineWings.material as MeshBasicMaterial).opacity = Math.min(1, this.scrollPosition - 3.8);
            (this.portholeLine.material as LineBasicMaterial).opacity = Math.min(1,this.scrollPosition - 3.8);

            if (this.scrollPosition > 4){
                (this.portholeLine.material as LineBasicMaterial).opacity = Math.max(0,4.5 - this.scrollPosition*0.98);
            }
        }


        let timestamp = performance.now();
        if(this.horseModel){

            let swingX = 0;
            let swingY = 0;
            let shiftX = 0;
            let shiftY = 0;

            if (window.innerWidth < 1440){
                swingX = window.innerWidth/300;
                shiftX = window.innerWidth/30000;
            }
            if (window.innerWidth < 1100){
                swingX = window.innerWidth/100;
                shiftX = window.innerWidth/10000;
            }
            if (window.innerWidth < 830){
                swingX = window.innerWidth/100;
                shiftX = window.innerWidth/5500;
            }

            if (window.innerWidth < 640){
                swingX = window.innerWidth/1000;
                shiftX =0.3;
                shiftY = 0.135;
                swingY = 20;
                this.horseModel.scale.setScalar(0.007);
            }
            if (window.innerWidth < 500){
                swingX = window.innerWidth/1000;
                shiftX =0.4;
                shiftY = 0.185 -  window.innerWidth/5000;
                swingY = 20;
            }

            const horseSwingLeftX = Math.sin(timestamp/1000)/(10 + swingX);
            const horseSwingLeftY = Math.sin(timestamp/2000)/(20 + swingY);

                this.horseModel.position.x =( 0.4 - shiftX )+ horseSwingLeftX;
            this.horseModel.position.y = (0.9 - shiftY) + horseSwingLeftY;

            this.horseModel.rotation.x += 0.01 * delta;
            this.horseModel.rotation.y += 0.01 * delta;
            this.horseModel.rotation.z += 0.01 * delta;




        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            const aspectRatio = width/height;
            this.camera.left = -aspectRatio / 2.0;
            this.camera.right = aspectRatio / 2.0;
            this.camera.updateProjectionMatrix();

        }
    }



    protected dispose(): void {
        this.light?.dispose();
        this.horseModel?.geometry.dispose();
        (this.horseModel?.material as MeshBasicMaterial).dispose();
        this.shipLineModel?.geometry.dispose();
        (this.shipLineModel?.material as LineBasicMaterial).dispose();
        this.shipLineWings?.geometry.dispose();
        (this.shipLineWings?.material as MeshBasicMaterial).dispose();
        this.portholeBlack?.geometry.dispose();
        this.portholeGreen?.geometry.dispose();
        this.portholeGreen?.geometry.dispose();
        (this.portholeLine?.material as LineBasicMaterial).dispose();
        (this.portholeGreen?.material as MeshBasicMaterial).dispose();
        (this.portholeBlack?.material as MeshBasicMaterial).dispose();

    }




}