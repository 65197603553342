import React, {useEffect, useRef} from 'react';
import styles from './Menu.module.scss'
import closePng from "MEDIA/images/menuClose.svg"
import clsx from "clsx";
import {MenuScene} from "../../graphics/scenes/MenuScene";
import {useGraphicsManager} from "../../graphics/hooks/useGraphicsManager";
import {useNoise} from "../../graphics/hooks/useNoise";

export interface MenuProps {
    isOpen?: boolean,
    setIsOpen: (flag : boolean) => void,
}

const Menu = ({isOpen, setIsOpen} : MenuProps) => {

    const blockRef = useRef<HTMLDivElement>(null);
    const {manager, canvas} = useGraphicsManager({
        className: styles.scenesCanvas
    });

    useEffect(() => {
        if (!manager || !blockRef.current) return;

        if(manager){
            const scene = new MenuScene(blockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }

    },[manager, blockRef])


    const scroll = (id: string) => {
            setIsOpen(false);
            const el = document.getElementById(id);
            el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const items = [
        {
            text:"web spa и мобильные приложения",
            id: "web-spa"
        },
        {
            text:"API Gateway",
            id: "api"
        },
        {
            text:"Services",
            id: "services"
        },
        {
            text:"Event bus",
            id: "events"
        },
        {
            text:"Gate",
            id: "gate"
        },
        {
            text:"другие сервисы",
            id: "other"
        },

    ];

    useNoise(manager, 0.08);


    return (
        <div className={clsx(styles.menuWrapper, isOpen && styles.open)}>
            {canvas}
            <div className={styles.menuWrapperScene} ref={blockRef}></div>
            <div className={clsx(styles.menuSideText)}>меню</div>
            <img className={styles.menuWrapperClose} src={closePng} alt="" onClick={() => setIsOpen(false)}/>
            <div className={clsx(styles.menuWrapperList, isOpen && styles.active)}>
                {items.map((el) => <h2 key={el.text} onClick={() => {
                    scroll(el.id!);
                }}>{el.text}</h2>)}
            </div>
        </div>
    );
};

export default Menu;