import React from 'react';
import styles from './ButtonBall.module.scss'

interface ButtonBallProps{
    text: string;
    onClick?: () => any;
    long?: boolean;
}

const ButtonBall = ({text,onClick, long } : ButtonBallProps) => {

    return (
        !long ?
            <div className={styles.wrapper} onClick={onClick}>
                <svg  className={styles.button} width={`232`} height="81" viewBox={`0 0 232 81`} rx="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.5" y="1" width="229" height="79" rx="40" fill="none" stroke="black"/>
                    <circle cx='40' cy="6" r="0" fill="none"/>
                </svg>
                <svg  className={styles.button2} width="270" height="102" viewBox="0 0 270 102" rx="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx='42' cy="6" r="6" fill="none"/>
                </svg>
                <p className={styles.text}>{text}</p>
            </div>
            :
            <div className={styles.long} onClick={onClick}>
                <svg  className={styles.longButton} width={`355`} height="88" viewBox={`0 0 355 88`} rx="50" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2.5" y="1" width="342" height="86" rx="50" fill="white" stroke="black"/>
                    <circle  r="3" fill="none"/>
                </svg>
                <svg  className={styles.longButton2} width="387" height="109" viewBox="0 0 387 109" rx="50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx='42' cy="6" r="6" fill="none"/>
                </svg>
                <p className={styles.longText}>{text}</p>
            </div>

    );
};

export default ButtonBall;