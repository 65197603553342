import {GraphicsScene} from "./GraphicsScene";
import {
    AdditiveBlending,
    AmbientLight,
    BufferGeometry,
    LineBasicMaterial,
    LineLoop, Mesh, MeshBasicMaterial,
    PerspectiveCamera, PlaneBufferGeometry,
    Sprite,
    SpriteMaterial,
    TextureLoader, Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import {loadingManager} from "./Banner/PreloaderScene";


export class MenuScene extends GraphicsScene{


    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];


    /**
     * Меш для круга
     * @private
     */
    private circlePlane: Mesh | null = null;

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;
    private spriteSmall : Sprite | null = null;


    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;

    /**
     * Позиция мыши
     * @private
     */
    private mouse: Vector2 = new Vector2(0, 0);

    private targetMouse: Vector2 = new Vector2(0, 0);



    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        const textureLoader = new TextureLoader(loadingManager);

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);

        const circleTexture = textureLoader.load("./models/textures/circleTexture.png")

        this.circlePlane = new Mesh(new PlaneBufferGeometry(), new MeshBasicMaterial({
            color: 0xff0000,
            blending: AdditiveBlending,
            transparent: true,
            alphaMap: circleTexture,
            opacity: 0.4,
        }));


        //Загрузка текстуры точки
        const dotMap = textureLoader.load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap } );

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 2.5;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );
        this.spriteSmall = this.sprite.clone();

        this.onMouseMove = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.onMouseMove);

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка овала
        if (this.ellipse && this.sprite && this.spriteSmall){
            //Установка овала
            this.ellipse.position.set(-0.1,0.3,-6);
            this.ellipse.rotation.set(0, 0, 0);

            // Установка точек
            this.sprite.scale.setScalar(0.05);
            this.sprite.position.set(0, 0, 2.5);

            this.spriteSmall?.scale.setScalar(0.04);
            this.spriteSmall.position.set(0.9,0, -2.33);

            if (window.innerWidth < 900){
                this.ellipse.scale.setScalar(0.25 + window.innerWidth/2000);
            }

            if (window.innerWidth < 400){
                this.ellipse.scale.setScalar(0.3 + window.innerWidth/2000);
            }

            this.ellipse.add(this.sprite, this.spriteSmall);
            this.scene.add(this.ellipse);
        }

        if (this.circlePlane){
            this.circlePlane.position.set(1.2, 0 , -12);
            this.circlePlane.scale.setScalar(17);

            if (window.innerWidth < 900){
                this.circlePlane.scale.setScalar(10 + window.innerWidth/2000);
            }

            this.scene.add(this.circlePlane);
        }

        // Установака света
        if (this.light){
            this.light.intensity = 0.95;
            this.scene.add(this.light);
        }

    }

    /**
     * Евенты на движение мыши
     * @param event
     * @protected
     */
    protected onMouseMove(event : MouseEvent){
        this.targetMouse.set(
            ( event.clientX / window.innerWidth ) * 2 - 1,
            ( event.clientY / window.innerHeight ) * -2 + 1,
        );
    }

    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        // Обновление позиции мыши
        this.mouse.add(this.targetMouse.clone().sub(this.mouse).multiplyScalar(0.1));

        // Анимации для шариков
        if (this.ellipse){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.rotation.set(0.33, (this.mouse.x/2 + this.mouse.y/2) - 1.4, 0.3, 'ZXY');

            if (window.innerWidth < 900){
                this.ellipse.rotation.set(0.36, (this.mouse.x/2 + this.mouse.y/2) - 1.4, 0.5, 'ZXY');

            }
        }
    }

    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera && height > 0) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
        this.sprite?.material.dispose();
        this.sprite?.geometry.dispose();

        this.light?.dispose();
        this.ellipse?.geometry.dispose();
        (this.ellipse?.material as LineBasicMaterial).dispose();
    }




}