import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    Color,
    DirectionalLight,
    LineBasicMaterial,
    LineLoop,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping, SpotLight,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";



export class WebScene extends GraphicsScene{


    /**
     * Меш для корабля
     * @private
     */
    private shipModel: Mesh | null = null;

    /**
     * Меш для планеты
     * @private
     */
    private earthModel: Mesh | undefined = undefined;

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет для бликов
     * @private
     */
    private  dirLight : DirectionalLight | null = null;
    private    spotLight : SpotLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;

    /**
     *  Меш для второго шарика
     * @private
     */
    private spriteSmall: Sprite | null = null;


    /**
     * Амплитуда для качания корабля
     * @private
     */
    private swingMark: number = 0

    /**
     * Скорость поворота кольца
     * @private
     */
    private ringTurn = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели корабля
        const loadModel = await loader.loadAsync("./models/station.glb");

        //  Установка модели корабля
        this.shipModel = loadModel.scene.getObjectByName("station") as Mesh;

        this.shipModel.material = new MeshPhongMaterial({
            map: texture,
            color: "#8AA1FF",
            shininess: 50
        });

        // Загрузка модели планеты
        const loadEarth = await loader.loadAsync("./models/Earth.glb");

        // Планета
        // Группа -  Empty
        // Земля  -  land
        // Шар(вода) - sphere

        // Установка меша земли
        this.earthModel = loadEarth.scene.getObjectByName("Empty") as Mesh;

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();
        this.spotLight = new SpotLight();


        //Загрузка текстуры точки
        const dotMap = textureLoader.load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap } );

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 1.6;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );

        // Инит амплитуды
        this.swingMark = 0;

        renderer.compile(this.scene, this.camera);

    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipModel ){

            this.shipModel.position.set(1.2,-0.485,-3.5);
            this.shipModel.rotation.set(Math.PI / 180 * 15,Math.PI / 180 * 140 ,Math.PI / 180 * -27);
            this.shipModel.scale.setScalar(0.6);
            this.shipModel.renderOrder = 2;

            if (window.innerWidth < 500){
                this.shipModel.scale.setScalar(0.7);
                this.shipModel.position.set(1,-0.5,-3.5);
            }

            this.scene.add(this.shipModel);
        }

        // Установка светильников
        if(this.dirLight && this.shipModel &&  this.spotLight){
            this.dirLight.intensity = 0.5;
            this.dirLight.target = this.shipModel;
            this.dirLight.position.set(-15,20,10);

            this.spotLight.color = new Color("#E786E2");
            this.spotLight.target = this.shipModel;
            this.spotLight.angle = Math.PI/10;
            this.spotLight.intensity =0.8;
            this.spotLight.distance= 6.8;
            this.spotLight.position.set(-0.8,-1,3);

            if (window.innerWidth < 500){
                this.spotLight.position.set(1.2,-1,2);
            }

            this.scene.add(this.dirLight, this.spotLight);

        }

        // Установка овала
        if (this.ellipse && this.sprite){
            //Установка овала
            this.ellipse.position.set(0.1,0,-6);
            this.ellipse.rotation.set(0, 0, 0);

            // Установка точек
            this.sprite.scale.setScalar(0.05);
            this.sprite.position.set(0, 0, 1.6);

            this.spriteSmall = this.sprite.clone();
            this.spriteSmall.scale.setScalar(0.02);
            this.spriteSmall.position.set(0, 0, -1.6);

            this.ellipse.add(this.sprite, this.spriteSmall);
            this.scene.add(this.ellipse);
        }

        // Установака света
        if (this.light){
            this.light.intensity = 0.95;
            this.scene.add(this.light);
        }

        // Установка планеты
        if (this.earthModel){
            this.earthModel.position.set(-0.9,0.3,-3.7)
            this.earthModel.rotation.set(0,0,Math.PI /180 * 45);
            this.earthModel.scale.setScalar(0.25);

            if (window.innerWidth < 500){
                this.earthModel.scale.setScalar(0.33);
                this.earthModel.position.set(-0.85,0.5,-3.7)
            }

            this.scene.add(this.earthModel);
        }


    }

    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {


        this.swingMark = (this.swingMark + 0.025 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipModel){
            this.shipModel.position.y += Math.sin(this.swingMark) *0.001;
        }

        // Анимации для шариков
        if (this.ellipse){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.rotation.set(0.3, this.ringTurn, -0.4, 'ZXY');

            if (window.innerWidth < 500){
                this.ellipse.rotation.set(0.3, this.ringTurn, -0.6, 'ZXY');
            }

        }

        //  Анимация планеты
        if(this.earthModel){
            this.earthModel.rotation.y += 0.01 * delta

        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
        this.dirLight?.dispose();
        this.sprite?.material.dispose();
        this.sprite?.geometry.dispose();
        this.light?.dispose();
        this.shipModel?.geometry.dispose();
        (this.shipModel?.material as MeshPhongMaterial).dispose();
        this.spotLight?.dispose();

    }




}