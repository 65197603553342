import React, { useContext, useEffect, useRef } from 'react';
import "./AboutBlock.scss"
import { ManagerContext } from "COMPONENTS/Wrapper/Wrapper";
import { ApiScene } from "../../graphics/scenes/ApiScene";
import { WebScene } from "../../graphics/scenes/WebScene";
import { ServicesScene } from "../../graphics/scenes/ServicesScene";
import { EventsScene } from "../../graphics/scenes/EventsScene";
import { GateScene } from "../../graphics/scenes/GateScene";
import { useCircle } from "../../graphics/scenes/CircleScene";
import { Color } from "three";
import {useSetActive} from "HOOKS/useSetActive";


const AboutBlock = () => {
    // Рефы для сцен
    const webBlockRef = useRef<HTMLDivElement>(null);
    const apiBlockRef = useRef<HTMLDivElement>(null);
    const servicesBlockRef = useRef<HTMLDivElement>(null);
    const eventsBlockRef = useRef<HTMLDivElement>(null)
    const gateBlockRef = useRef<HTMLDivElement>(null);

    const manager = useContext(ManagerContext);

    useSetActive([
        {
            elementId: "web-spa"
        },
        {
            elementId: "api"
        },
        {
            elementId: "services"
        },
        {
            elementId: "events"
        },
        {
            elementId: "gate"
        }
    ])




    // Инит сцены в блоке api
    useEffect(() => {
        if (manager) {
            if (!manager || !apiBlockRef.current) return;

            const scene = new ApiScene(apiBlockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    }, [manager, apiBlockRef.current])

    // Инит сцены в блоке wev
    useEffect(() => {
        if (!manager || !webBlockRef.current) return;

        if (manager) {
            const scene = new WebScene(webBlockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }

    }, [manager, webBlockRef.current]);

    // Инит сцены в блоке services
    useEffect(() => {
        if (!manager || !servicesBlockRef.current) return;

        if (manager) {
            const scene = new ServicesScene(servicesBlockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }

    }, [manager, servicesBlockRef.current])

    // Инит сцены в блоке event
    useEffect(() => {
        if (!manager || !eventsBlockRef.current) return;

        if (manager) {
            const scene = new EventsScene(eventsBlockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    }, [manager, eventsBlockRef.current])

    // Инит сцены в блоке gate
    useEffect(() => {
        if (!manager || !gateBlockRef.current) return;

        if (manager) {
            const scene = new GateScene(gateBlockRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    }, [manager, gateBlockRef.current])


    // Круги для блока веб
    const webCircleRedRef = useRef<HTMLDivElement>(null);
    const webCircleWhiteRef = useRef<HTMLDivElement>(null);
    useCircle(manager, webCircleRedRef, { color: new Color("red"), scale: 8, renderOrder: 10 })
    useCircle(manager, webCircleWhiteRef, { color: new Color("white"), scale: 8, renderOrder: 5, opacity: 1.5 })

    // Круги для блока апи
    const apiCircleRedRef = useRef<HTMLDivElement>(null);
    const apiCircleWhiteRef = useRef<HTMLDivElement>(null);
    useCircle(manager, apiCircleRedRef, { color: new Color("red"), scale: 8, renderOrder: 10 })
    useCircle(manager, apiCircleWhiteRef, { color: new Color("white"), scale: 8, renderOrder: 5, opacity: 1.5 })

    //Круги для блока services
    const servicesCircleLeftRef = useRef<HTMLDivElement>(null);
    const servicesCircleRightRef = useRef<HTMLDivElement>(null);
    useCircle(manager, servicesCircleLeftRef, { color: new Color(0xADFF00), scale: 8, opacity: 0.25 })
    useCircle(manager, servicesCircleRightRef, { color: new Color(0xADFF00), scale: 8, opacity: 0.25 })

    //Круги для блока events
    const eventsCircleGreen = useRef<HTMLDivElement>(null);
    const eventsCircleWhite = useRef<HTMLDivElement>(null);
    useCircle(manager, eventsCircleGreen, { color: new Color(0xADFF00), scale: 8, opacity: 0.25 })
    useCircle(manager, eventsCircleWhite, { color: new Color(0xffffff), scale: 8, opacity: 1.2 })


    return (
        <div className="AboutWrapper">
            <div className={`AboutWrapper--item`} id={"web-spa"} >
                <div className={"AboutWrapper-webScene"} ref={webBlockRef}></div>
                <div className={`circle-web-red`} ref={webCircleRedRef}></div>
                <div className={`circle-web-white`} ref={webCircleWhiteRef}></div>
                <h2>Что видит пользователь: <br></br>
                    WEB SPA и мобильные приложения</h2>
                <p>Космическая станция может работать автономно, но&nbsp;мы&nbsp;хотим осуществлять мониторинг и&nbsp;управление на&nbsp;Земле.
                     У&nbsp;нас для этого даже свои терминалы связи есть &nbsp;—&nbsp; клиентские приложения или&nbsp;SPA&nbsp;(Single-Page Applications).
                </p>
                <p> SPA&nbsp;—&nbsp;это код, загружаемый с&nbsp;сайта и&nbsp;работающий в&nbsp;браузере на&nbsp;вашем компьютере. Эта часть скрыта от&nbsp;глаз пользователя. </p>
                <p>Клиентское приложение&nbsp;—&nbsp;это&nbsp;надписи, кнопки и картинки, с&nbsp;которыми взаимодействует пользователь. Это то, что&nbsp;он&nbsp;видит на&nbsp;своем мониторе или экране. Клиентские приложения не&nbsp;только визуализируют контент, но&nbsp;и&nbsp;отправляют запросы на backend. </p>
            </div>


            <div className={`AboutWrapper--item`} id={"api"} >
                <div className={"AboutWrapper-apiScene"} ref={apiBlockRef}></div>
                <div className={`circle-api-red`} ref={apiCircleRedRef}></div>
                <div className={`circle-api-white`} ref={apiCircleWhiteRef}></div>
                <h2>Распределение запросов: <br />
                    API Gateway</h2>

                <p>Терминалы посылают управляющий сигнал на&nbsp;антенну космической станции. Она принимает сигналы и&nbsp;передает ее соответствующему модулю. API Gateway&nbsp;—&nbsp;та&nbsp;самая антенна для микросервисов.</p>
                <p>Приложение получает единую точку входа при запросе данных из&nbsp;любого микросервиса. В свою очередь пользователь не&nbsp;видит то, что происходит на&nbsp;backend по&nbsp;ту&nbsp;сторону станции во&nbsp;время его запроса, ему эта информация не&nbsp;нужна. </p>

                <div className={`AboutWrapper-scene`}></div>
            </div>

            <div className={`AboutWrapper--item`} id={"services"}>
                <div className={"AboutWrapper-servicesScene"} ref={servicesBlockRef}></div>
                <div className={`circle-services-left`} ref={servicesCircleLeftRef}></div>
                <div className={`circle-services-right`} ref={servicesCircleRightRef}></div>
                <h2>Масштабирование <br />
                    продукта: Services</h2>
                <p>
                    Станция состоит из&nbsp;модулей. Один занимается научными исследованиями, второй вырабатывает кислород, третий обеспечивает стабильную траекторию, корректируя движение двигателями. У&nbsp;нас каждый модуль&nbsp;—&nbsp;это микросервис.
                </p>
                <p>
                    Что делать, если мощности двигателя не&nbsp;хватает? Берем двигатель побольше&nbsp;—&nbsp;это называется вертикальным масштабированием. А&nbsp;можно поставить рядом второй такой же двигатель в&nbsp;помощь первому и&nbsp;тогда масштабирование будет горизонтальным.
                </p>
                <p>
                    На настоящую космическую станцию новые модули нужно доставлять с&nbsp;Земли, но&nbsp;нам этого делать не&nbsp;нужно: мы&nbsp;всегда можем создавать новые контейнеры по&nbsp;мере роста нагрузки. Или отключать их, когда нагрузка падает. Экономия на ресурсах, получается.
                </p>
            </div>


            <div className={`AboutWrapper--item`} id={"events"}>
                <div className={"AboutWrapper-eventsScene"} ref={eventsBlockRef}></div>
                <div className={`circle-events-green`} ref={eventsCircleGreen}></div>
                <div className={`circle-events-white`} ref={eventsCircleWhite}></div>
                <h2>Оперативный обмен <br />
                    информацией: Event bus </h2>
                <p>Связь между модулями нашей космической станции осуществляется с помощью пневмопочты, например,
                    <br /> как в почтовых отделениях. Для микросервисов такая внутренняя пневмопочта&nbsp;—&nbsp;это Event Bus.
                </p>
                <p>С&nbsp;ее&nbsp;помощью микросервисы могут асинхронно обмениваться информацией, публиковать события, важные другим компонентам и подписываться на чужие события.</p>
            </div>


            <div className={`AboutWrapper--item`} id={"gate"}>
                <div className={"AboutWrapper-gateScene"} ref={gateBlockRef}></div>
                <h2>Связь с внешними <br />
                    сервисами: Gate</h2>
                <p>Космическая станция это не конь в&nbsp;вакууме
                    <br />  (хотя в вакууме, да). Она взаимодействует с&nbsp;другими системами. Например, получает данные о&nbsp;космической радиации или о&nbsp;межгалактических ивентах.
                </p>
                <p>Чтобы быть всегда на связи, микросервисная система тоже использует сторонние сервисы для совершения платежей, рассылки SMS, получения электронной подписи&nbsp;чего угодно.
                    Для того, чтобы не&nbsp;зависеть от&nbsp;вендора, мы&nbsp;используем Gates. Это словно двери во&nbsp;внешний мир, которые нужны для взаимодействия с&nbsp;внешними сервисами.
                </p>
            </div>


        </div>
    );
};

export default AboutBlock;