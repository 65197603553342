import {ReactNode, useEffect, useRef, useState} from "react";
import {GraphicsManager} from "../GraphicsManager";

interface ManagerConfig {
    manager: GraphicsManager | undefined,
    canvas: ReactNode
}

interface Props {
    managerType?: typeof GraphicsManager,
    id?: string,
    className?: string,
    releaseScenesOnDispose?: boolean,
}

export function useGraphicsManager({
    managerType,
    id,
    className,
    releaseScenesOnDispose
}: Props) : ManagerConfig {

    // Привязка менеджера
    const [manager, setManager] = useState<GraphicsManager | undefined>(undefined);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvasRef.current) return;
        setManager(new (managerType ? managerType : GraphicsManager)(canvasRef.current));
        return () => {
            if (manager) {
                manager.dispose(releaseScenesOnDispose === undefined ? true : releaseScenesOnDispose);
            }
            setManager(undefined);
        }
    }, [canvasRef])

    // Рендер
    return {
        manager,
        canvas: <canvas
            ref={canvasRef}
            id={id}
            className={className}
        />
    };
}
