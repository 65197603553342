import React from "react";
import styles from "./MenuIcon.module.scss"
import {MenuProps} from "COMPONENTS/Menu/Menu";

interface MenuIcon extends MenuProps{}

const MenuIcon = ({setIsOpen}: MenuIcon) => {

    return (
        <div className={styles.menuIconWrapperWrapper}>
            <div className={styles.menuIconWrapper}  onClick={() => setIsOpen(true)}>
                <div className={styles.menuIcon}>
                    <svg width="22" height="22" viewBox="0 0 22 22"  xmlns="http://www.w3.org/2000/svg">
                        <rect width="10" height="10" />
                        <rect x="12" width="10" height="10" />
                        <rect x="12" y="12" width="10" height="10" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default MenuIcon;