import React from 'react';
import styles from './SoundIcon.module.scss'
import clsx from "clsx";

export interface SoundProps{
    toggle? :  boolean | (() => void);
    playing?:   boolean | (() => void);
    isMobile?: boolean;
}

const SoundIcon = ({toggle, playing, isMobile } : SoundProps) => {
    return (
        // @ts-ignore
        <div className={!isMobile ? styles.soundWrapper : styles.soundWrapperMobile} onClick={toggle}>
            <div className={clsx(!isMobile ? styles.soundWrapperIcon : styles.soundWrapperMobileIcon, !playing ?  styles.active :"")}>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2192_240)">
                        <path d="M30.7098 22.053C30.7098 17.421 26.8103 13.666 22.0001 13.666C17.19 13.666 13.2905 17.421 13.2905 22.053" stroke="white" strokeWidth="1.29031"/>
                        <rect x="12" y="23.0781" width="2.35292" height="7.05877" fill="white"/>
                        <rect x="29.6469" y="23.0781" width="2.35292" height="7.05877" fill="white"/>
                        <path className={styles.soundWrapperIconOff} d="M11 16L34 29" stroke="white"/>
                        <circle cx="22" cy="22" r="21.5" stroke="white"/>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default SoundIcon;