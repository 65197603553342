import React, { createContext, useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';
import TitleBlock from "COMPONENTS/TitleBlock/TitleBlock";
import SideText from "COMPONENTS/SideText/SideText";
import EyesBlock from "COMPONENTS/EyesBlock/EyesBlock";
import AboutBlock from "COMPONENTS/AboutBlock/AboutBlock";
import AboutExtra from "COMPONENTS/AboutExtra/AboutExtra";
import Footer from "COMPONENTS/Footer/Footer";
import Menu from "COMPONENTS/Menu/Menu";
import { GraphicsManager } from "../../graphics/GraphicsManager";
import { useGraphicsManager } from "../../graphics/hooks/useGraphicsManager";
import { useNoise } from "../../graphics/hooks/useNoise";
import MenuIcon from "COMPONENTS/Menu/MenuIcon";
import Details from "COMPONENTS/Details/Details";
import SoundIcon from "COMPONENTS/Sound/SoundIcon";
import { useAudio } from "HOOKS/useAudio";
import sound from '../../../media/audio/ambient.mp3'
import Banner from "COMPONENTS/Banner/Banner";

export const ManagerContext = createContext<GraphicsManager | undefined>(undefined);

const Wrapper = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isBanner, setIsBanner] = useState<boolean>(true);
    const [playing, toggle] = useAudio(sound);

    // Выключение скролла при открытии меню
    useEffect(() => {
        if (isMenuOpen || isBanner) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
    }, [isMenuOpen, isBanner])

    // Установка канваса
    const { manager, canvas } = useGraphicsManager({
        className: styles.scenesCanvas,
        id: "canvas"
    });

    //Шум
    useNoise(manager, 0.08);

        // let magicScroll = new MagicScroll({
        //     target: document.querySelector(".scroll-view.after"),
        //     speed: 120,
        //     smooth: 50
        // });

    return (
        <> {canvas}
            {isBanner && <Banner toggleMusic={toggle} setIsBanner={setIsBanner} />}
            <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
            <div className={styles.wrapper} style={isBanner ? { display: 'none' } : {}} id={"wrapper"}>
                <ManagerContext.Provider value={manager}>
                    <MenuIcon setIsOpen={setIsMenuOpen} />
                    {window.innerWidth < 500 && <SoundIcon toggle={toggle} playing={playing} isMobile={true} />}
                    <SideText />
                    <TitleBlock />
                    <EyesBlock />
                    <AboutBlock />
                    <AboutExtra />
                    <Details />
                    <Footer toggle={toggle} playing={playing} />
                </ManagerContext.Provider>
            </div>
        </>

    );
};

export default Wrapper;