import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './SideText.module.scss';
import clsx from "clsx";

import {SideTextScene} from "../../graphics/scenes/SideTextScene";
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";


const SideText = () => {
    const manager = useContext(ManagerContext);
    const ref = useRef<HTMLDivElement>(null);
    const [sideText, setSideText] = useState<string>("просто о сложном");
    const [toggle, setToggle] = useState<boolean>(true);
    const [isScene, setIsScene] = useState<boolean>(false);
    const sceneRef = useRef<SideTextScene | null>(null);

    // useActiveBlock(['title', 'eye', 'other-list', 'web-spa', 'gate']);

    // Сцена
    const sceneBlockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // if (!manager || !sceneRef.current) return;

        if(manager){
            const scene = new SideTextScene(sceneBlockRef.current);
            sceneRef.current = scene;
            scene.active = isScene;
            manager.addScene(scene);

            return () => {
                sceneRef.current = null;
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, sceneBlockRef])

    useEffect(() => {
        if (sceneRef.current) sceneRef.current.active = isScene;
    }, [isScene])


    let eyeThreshold = 0.4 + window.innerHeight/4300;

    if (window.innerHeight > 1000){
        eyeThreshold = 0.5 + window.innerHeight/4300;
    }

    const eyeObserver = new IntersectionObserver((entry) => {

        if (entry[0].intersectionRatio >= eyeThreshold) {
            setSideText("Давайте посмотрим");
            setToggle(false);
        }
        else {
            setToggle(true);
        }

    }, {  root:null, threshold:eyeThreshold})

    let titleThreshold = 0.35;
    const titleObserver = new IntersectionObserver((entry) => {

        if (entry[0].intersectionRatio >= titleThreshold) {
            setSideText("Просто о сложном");
            setToggle(false);
        }
        else {
            setToggle(true);
        }

    }, {  root:null, threshold:titleThreshold})

    // Блок со сценой

    let otherThreshold = 0.33;
    if (window.innerHeight < 1200){
        otherThreshold = 0.33 - (1200 -  window.innerHeight)/2300;
    }
    const otherObserver = new IntersectionObserver((entry) => {

        if (entry[0].intersectionRatio >= otherThreshold) {
            setSideText("ещё мы используем");
            setToggle(false);
            setIsScene(true);
        }
        else {
            setToggle(true);
            setIsScene(false);
        }

    }, {  root:null, threshold:otherThreshold})


    useEffect(()=> {
        if(ref.current){
            const title = document.getElementById("title");
            const eyes = document.getElementById("eye");
            const other = document.getElementById("other-list");

            eyeObserver.observe(eyes!);
            titleObserver.observe(title!);
            otherObserver.observe(other!);


        }

        setTimeout(() => {
            setToggle(false);
        },500)
    }, []);



    return (
        <div className={clsx(styles.wrapper, toggle && styles.disappear)} ref={ref} onClick={() => setToggle(!toggle)}>
            <div className={clsx(styles.wrapperFixed,toggle && styles.disappear)}>
                <h3 className={styles.text}>
                    {sideText}
                </h3>
                <div className={clsx(styles.SceneWrapper, isScene && styles.appear)}>
                    <div className={clsx(styles.SceneWrapperCircle)}></div>
                    <div className={styles.SceneWrapperScene} ref={sceneBlockRef}></div>
                </div>
            </div>
        </div>
    );
};

export default SideText;