import {WebGLRenderer} from 'three';
import {GraphicsScene} from './scenes/GraphicsScene';

const QUALITY_TIERS = [
    0.25,
    0.5,
    0.75,
    1.0,
    1.5,
];

/**
 * Структура для одной сцены
 */
interface SceneStruct {
    scene: GraphicsScene,
    element: HTMLElement | null,
    elementVisible: boolean,
    rect: DOMRect,
    visible: boolean,
    dirty: boolean,
}

/**
 * Менеджер графики
 */
export class GraphicsManager {

    /**
     * Количество семплов для FPS
     * @private
     */
    private static readonly FPS_SAMPLES = 64;

    /**
     * Флаг адаптивного качества
     * @private
     */
    public static allowQualityTiers: boolean = false;

    /**
     * Счетчик до проверки качества
     * @private
     */
    private static qualityCheckCounter = GraphicsManager.FPS_SAMPLES;

    /**
     * Счетчик для определения герцовки монитора
     * @private
     */
    private static monitorFreqCounter = 16;

    /**
     * Значения дельты для рассчета герцовки монитора
     * @private
     */
    private static monitorDeltaArray: number[] = [];

    /**
     * Полученная герцовка монитора
     * @private
     */
    private static monitorFrequency = 60;

    /**
     * Текущий активный менеджер
     * @private
     */
    private static activeManager: GraphicsManager;

    /**
     * Список всех менеджеров
     * @private
     */
    private static managers: GraphicsManager[] = [];

    /**
     * Индекс запроса для RequestAnimationFrame
     * @private
     */
    private static rafQuery: number = 0;

    /**
     * Прошлое время кадра
     * @private
     */
    private static rafTime: number = 0;

    /**
     * Текущее значение качества
     * @private
     */
    private static qualityTier = QUALITY_TIERS.indexOf(1);

    /**
     * Флаг высвобождения контекста
     * @private
     */
    private disposed: boolean = false;

    /**
     * Флаг отсутствия активных сцен в следующем кадре
     * @private
     */
    private lastFrameRendered: boolean = false;

    /**
     * Связанный канвас
     * @private
     */
    private canvas: HTMLCanvasElement;

    /**
     * Размеры канваса
     * @private
     */
    private readonly canvasRect = new DOMRect(0, 0, 0, 0);

    /**
     * THREE-рендерер
     * @private
     */
    private readonly renderer: WebGLRenderer;

    /**
     * Observer для отслеживания дочерних элементов
     * @private
     */
    private readonly observer: IntersectionObserver;

    /**
     * Флаг нахождения канваса в области видимости
     * @private
     */
    private inView: boolean = true;

    /**
     * Флаг для пересчета расположений
     * @private
     */
    private needReflow: boolean = false;

    /**
     * Флаг на инициализацию
     * @private
     */
    private initialized: boolean = false;

    /**
     * Обновление сцен
     * @private
     */
    private scenes: SceneStruct[] = [];

    /**
     * Значения FPS для взвешенного вычисления
     * @private
     */
    private static fpsSamples: number[] = [];

    /**
     * Текущее значение FPS
     * @private
     */
    private static fps: number = 0;

    /**
     * Конструктор менеджера
     * @param canvas
     */
    public constructor(canvas: HTMLCanvasElement) {
        this.disposed = false;
        this.initialized = false;
        this.canvas = canvas;
        this.renderer = new WebGLRenderer({
            canvas,
            antialias: true,
            stencil: true,
            powerPreference: 'high-performance',
        });
        this.observer = new IntersectionObserver((entries) => {
            for (let obs of entries) {
                if (obs.target === canvas) {
                    this.inView = obs.isIntersecting;
                } else {
                    const entry = this.scenes.find(e => e.element === obs.target);
                    if (entry) {
                        entry.elementVisible = obs.isIntersecting;
                        entry.dirty = true;
                    }
                }
            }
        }, {
            // root: canvas,
        });
        this.observer.observe(canvas);

        GraphicsManager.managers.push(this);

        console.log('[GraphicsManager] Subscribed new instance');

        setTimeout(() => {
            if (this.scenes){
                this.scenes.forEach((scene) => {
                        scene.scene.preloadResources(this.renderer);
                    }
                )
            }
        },200)



        if (GraphicsManager.managers.length === 1) {
            console.log('[GraphicsManager] Starting renderer');

            // Запуск рендера и подписка на ивенты
            setTimeout(() => {
                GraphicsManager.resizeEvent = GraphicsManager.resizeEvent.bind(GraphicsManager);
                GraphicsManager.scrollEvent = GraphicsManager.scrollEvent.bind(GraphicsManager);
                GraphicsManager.renderLoop = GraphicsManager.renderLoop.bind(GraphicsManager);
                GraphicsManager.resizeEvent();
                GraphicsManager.renderLoop(performance.now());

                window.addEventListener('resize', GraphicsManager.resizeEvent);
                window.addEventListener('scroll', GraphicsManager.scrollEvent);
            }, 0);
        }
    }

    /**
     * Высвобождение рендера
     * @param releaseScenes
     */
    public dispose(releaseScenes: boolean = true) {
        // Удаление из общей подписки
        const idx = GraphicsManager.managers.indexOf(this);
        if (idx !== -1) {
            GraphicsManager.managers.splice(idx, 1);
        }

        // Отключение обработки отсечения
        this.observer.disconnect();

        // Если нужно снести сцены - сносим
        if (releaseScenes) {
            for (const entry of this.scenes) {
                entry.scene.sceneRemoved();
                entry.scene.disposeScene();
            }
        }

        // Остановка RAF, если это последний менеджер
        console.log('[GraphicsManager] Unsubscribed');
        if (GraphicsManager.managers.length === 0) {
            cancelAnimationFrame(GraphicsManager.rafQuery);
            GraphicsManager.rafQuery = 0;
            console.log('[GraphicsManager] Stopping whole render');
        }
    }

    /**
     * Обновление логики сцен
     * @param tween
     * @private
     */
    private updateSceneLogic(tween: number) {
        this.update(tween);

        if (this.needReflow) {

            // Вычисление и обновление размеров канваса
            const newRect = this.canvas.getBoundingClientRect();
            if (
                this.canvasRect.x !== newRect.x ||
                this.canvasRect.y !== newRect.y ||
                this.canvasRect.width !== newRect.width ||
                this.canvasRect.height !== newRect.height
            ) {
                this.canvasRect.x = newRect.x;
                this.canvasRect.y = newRect.y;
                this.canvasRect.width = newRect.width;
                this.canvasRect.height = newRect.height;
                this.resize();
            }
        }

        // Отсекаем сцены и обновляем логику
        for (const entry of this.scenes) {
            if (entry.scene.active) {

                // Если у сцены поменялся рект - обновляем
                const sceneRect = DOMRect.fromRect(entry.scene.getSize());
                if (
                    sceneRect.x !== entry.rect.x ||
                    sceneRect.y !== entry.rect.y ||
                    sceneRect.width !== entry.rect.width ||
                    sceneRect.height !== entry.rect.height
                ) {
                    entry.dirty = true;
                }

                // Если нужно пересчитать сцену - пересчитываем
                if (entry.dirty || entry.element) {
                    const startSize = DOMRect.fromRect(sceneRect);

                    if (entry.element) {
                        entry.visible = entry.elementVisible;

                        // Если у сцены есть элемент - смотрим его размеры
                        if (entry.elementVisible) {
                            const elemRect = entry.element.getBoundingClientRect();
                            startSize.x += elemRect.x - this.canvasRect.x;
                            startSize.y += elemRect.y - this.canvasRect.y;
                            if (startSize.width === 0) startSize.width = elemRect.width;
                            if (startSize.height === 0) startSize.height = elemRect.height;
                        }

                    } else {

                        // Сцена висит на канвасе
                        entry.visible = true;
                        if (startSize.width === 0) startSize.width = this.canvasRect.width;
                        if (startSize.height === 0) startSize.height = this.canvasRect.height;

                    }

                    // Если все-таки сцена видна, попробуем отсечь
                    if (entry.visible) {
                        if (
                            startSize.x + startSize.width < 0 ||
                            startSize.y + startSize.height < 0 ||
                            startSize.x > this.canvasRect.width ||
                            startSize.y > this.canvasRect.height
                        ) {
                            // entry.visible = false;
                        }
                    }
                    entry.dirty = false;

                    // Обновление ректангла для рендера
                    const mult = QUALITY_TIERS[GraphicsManager.qualityTier] * window.devicePixelRatio;
                    startSize.x *= mult;
                    startSize.y = (this.canvasRect.height - (startSize.y + startSize.height)) * mult;
                    startSize.width *= mult;
                    startSize.height *= mult;
                    entry.scene.updateRenderSize(startSize);
                }

                // Обновление логики
                if (entry.visible || entry.scene.needOffscreenUpdate()) entry.scene.updateLogic(tween, entry.visible);
            }
        }
        this.needReflow = false;
    }

    /**
     * Обновление размера экрана
     * @private
     */
    private resize() {
        const dpi = window.devicePixelRatio;
        const quality = QUALITY_TIERS[GraphicsManager.qualityTier];
        const pixelWidth = this.canvasRect.width * quality * dpi;
        const pixelHeight = this.canvasRect.height * quality * dpi;
        this.canvas.width = pixelWidth;
        this.canvas.height = pixelHeight;
        this.renderer.setSize(pixelWidth, pixelHeight, false);
        for (const entry of this.scenes) {
            entry.dirty = true;
        }
    }

    /**
     * Рендеринг сцен
     * @private
     */
    private renderScenes() {
        const list = [...this.scenes]
            .filter((entry) => entry.scene.active && entry.visible && entry.scene.needRepaint())
            .map((item) => ({
                entry: item,
                weight: item.scene.getOrder(),
            }))
            .sort((a, b) => b.weight - a.weight);

        this.renderer.autoClear = false;
        if (list.length || this.lastFrameRendered) {
            this.renderer.setRenderTarget(null);
            this.renderer.setClearColor(0xffffff, 0);
            this.renderer.clear();
            this.lastFrameRendered = false;
        }

        if (list.length > 0) {
            for (const item of list) {
                item.entry.scene.renderFrame(this.renderer);
            }
            this.lastFrameRendered = true;
        }
    }

    /**
     * Добавление сцены
     * @param scene
     */
    public addScene(scene: GraphicsScene) {
        const entry = this.getEntryByScene(scene);
        if (!entry) {
            if (scene.element) {
                this.observer.observe(scene.element);
            }
            this.scenes.push({
                scene,
                element: scene.element,
                elementVisible: false,
                visible: false,
                rect: new DOMRect(0, 0, 0, 0),
                dirty: true,
            });

            scene.sceneAdded();
        }
    }

    /**
     * Удаление сцены
     * @param scene
     */
    public removeScene(scene: GraphicsScene) {
        const entry = this.getEntryByScene(scene);
        if (entry) {
            this.scenes.splice(this.scenes.indexOf(entry), 1);
            entry.scene.sceneRemoved();
        }
    }

    /**
     * Проверка на инит
     */
    public isInitialized() {
        return this.initialized;
    }

    /**
     * Получение структуры по сцене
     * @param scene
     * @private
     */
    private getEntryByScene(scene: GraphicsScene) {
        return this.scenes.find(ent => ent.scene === scene);
    }

    /**
     * Внутренний инит ресурсов
     * @protected
     */
    protected init() {};

    /**
     * Обновление всего менеджера
     * @param tween
     * @protected
     */
    protected update(tween: number) {};

    /**
     * Циклическая отрисовка
     * @private
     */
    private static renderLoop(elapsed: number) {
        this.rafQuery = requestAnimationFrame(this.renderLoop);
        const delta = (elapsed - this.rafTime) / 16.666;
        this.rafTime = elapsed;

        // Обновление FPS
        if (this.fpsSamples.length >= this.FPS_SAMPLES) {
            this.fpsSamples = this.fpsSamples.slice(this.fpsSamples.length - this.FPS_SAMPLES + 1, this.fpsSamples.length);
        }
        this.fpsSamples.push((1.0 / delta) * 60.0);
        this.fps = this.fpsSamples.reduce(((previousValue, item) => previousValue + item));
        this.fps = Math.ceil(this.fps / this.fpsSamples.length);

        // Высчитывание герцовки монитора
        if (this.monitorFreqCounter > 0) {
            if (this.monitorFreqCounter < 10) {
                this.monitorDeltaArray.push((1.0 / delta) * 60.0);
            }
            this.monitorFreqCounter--;
            if (this.monitorFreqCounter === 0) {
                this.monitorFrequency = Math.ceil(this.monitorDeltaArray.reduce(((previousValue, item) => previousValue + item)) / this.monitorDeltaArray.length);
                console.log(`[GraphicsManager] Screen refresh rate - ${this.monitorFrequency}hz`);
            }
            return;
        }

        // Обработка повышения/понижения качества
        if (this.allowQualityTiers) {
            if (this.qualityCheckCounter === 0) {
                const fpsThreshold = 10;
                let targetQuality = this.qualityTier;
                if (this.fps < this.monitorFrequency - fpsThreshold) {
                    targetQuality = Math.max(this.qualityTier - 1, 0);
                } else if (this.fps > this.monitorFrequency + fpsThreshold) {
                    targetQuality = Math.min(this.qualityTier + 1, QUALITY_TIERS.length - 1);
                }
                if (targetQuality !== this.qualityTier) {
                    this.qualityTier = targetQuality;
                    for (const man of this.managers) {
                        GraphicsManager.activeManager = man;
                        man.resize();
                    }
                }
                this.qualityCheckCounter = this.FPS_SAMPLES;
            } else {
                this.qualityCheckCounter--;
            }
        }

        // Обновление логики экранов
        for (const man of this.managers) {
            GraphicsManager.activeManager = man;
            if (!man.initialized) {
                man.initialized = true;
                man.init();
            }
            man.updateSceneLogic(delta);
        }

        // Рендер
        for (const man of this.managers) {
            GraphicsManager.activeManager = man;
            man.renderScenes();
        }
    }

    /**
     * Определение ресайза
     * @private
     */
    private static resizeEvent() {
        this.scrollEvent();
    }

    private static scrollEvent() {
        for (const man of this.managers) {
            man.needReflow = true;
        }
    }

    /**
     * Получение активного рендера
     */
    public static getActiveRenderer() {
        if (GraphicsManager.activeManager) {
            return GraphicsManager.activeManager.renderer;
        }
        return null;
    }

    /**
     * Получение FPS
     */
    public static getFPS() {
        return this.fps;
    }
}
