import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    Color,
    DirectionalLight,
    LineBasicMaterial,
    LineLoop,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";


export class TitleScene extends GraphicsScene{


    /**
     * Меш для корабля
     * @private
     */
    private shipModel: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет для бликов
     * @private
     */
    private  dirLight : DirectionalLight | null = null;
    private  dirLightRed : DirectionalLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private circleLine: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private ellipse: LineLoop | null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;

    /**
     *  Меш для второго шарика
     * @private
     */
    private spriteSmall: Sprite | null = null;

    /**
     * Метка для анимации качания
     * @private
     */
    private swingMark: number = 0

    /**
     * Метка для вращения кольца
     * @private
     */
    private ringTurn = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {



        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели корабля
        const loadModel = await loader.loadAsync("./models/station.glb");

        //  Установка модели корабля
        this.shipModel = loadModel.scene.getObjectByName("station") as Mesh;

        this.shipModel.material = new MeshPhongMaterial({
            map: texture,
            color: "#8AA1FF",
            shininess: 40,
        });

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);

        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();
        this.dirLightRed = new DirectionalLight();


        //Загрузка текстуры точки
        const dotMap = new TextureLoader().load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap } );

        // Задание координат овала
        for (let i = 0; i < 70; i++) {

            // Установка угла и дитанции от центра
            let dist = 1.9;
            let angle = (Math.PI) / 35 * i;

            // Установка позиционирования
            let x = Math.sin(angle) * dist;
            let y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, 0, y));

        }

        // Инит геометрия для овала
        const circleGeometry = new BufferGeometry().setFromPoints( this.circleLine );

        // Инит материа для овала
        const material = new LineBasicMaterial({
            color: "black"
        });

        // Инит овала
        this.ellipse = new LineLoop( circleGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );

        this.swingMark = 0;

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipModel ){
            this.shipModel.position.set(0.3,0.7,-5);
            this.shipModel.rotation.set(Math.PI / 180 * 32,Math.PI / 180 * 152 ,Math.PI / 180 * -18);
            this.shipModel.scale.setScalar(0.9);

            if(window.innerWidth < 500){
                this.shipModel.scale.setScalar(1.2);
            }

            this.scene.add(this.shipModel);
        }

        // Установка света
        if(this.dirLight && this.shipModel &&  this.dirLightRed){

            // Белый
            this.dirLight.intensity = 0.6;
            this.dirLight.target = this.shipModel;
            this.dirLight.position.set(-2,2,-4);

            //Красный
            this.dirLightRed.color = new Color("#E786E2");
            this.dirLightRed.target = this.shipModel;
            this.dirLightRed.intensity =0.6;
            this.dirLightRed.position.set(6,1,-5);

            this.scene.add(this.dirLight, this.dirLightRed);

        }

        // Установка овала
        if (this.ellipse && this.sprite){
            //Установка овала
            this.ellipse.position.set(0.1,0.72,-6);
            this.ellipse.rotation.set(0, 0, 0);

            // Установка точек
            this.sprite.scale.setScalar(0.04);
            this.sprite.position.set(0, 0, 1.9);

            this.spriteSmall = this.sprite.clone();
            this.spriteSmall.scale.setScalar(0.03);
            this.spriteSmall.position.set(0, 0, -1.9);

            if(window.innerWidth < 500){
                this.ellipse.scale.setScalar(1.17)
            }

            this.ellipse.add(this.sprite, this.spriteSmall);
            this.scene.add(this.ellipse);

        }

        // Установака света
        if (this.light){
            this.light.intensity = 0.95;
            this.scene.add(this.light);
        }
    }

    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        // Амплитуда для качания корабля
        this.swingMark = (this.swingMark + 0.02 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipModel){
             this.shipModel.position.y += Math.sin(this.swingMark) *0.001;

        }

        // Анимации для шариков
        if (this.ellipse){
            this.ringTurn = (this.ringTurn + 0.01 * delta) % (Math.PI * 2);
            this.ellipse.rotation.set(0.3, this.ringTurn, 0.3, 'ZXY');

            if(window.innerWidth < 500){
                this.ellipse.rotation.set(0.5, this.ringTurn, 0.7, 'ZXY');
            }
        }

    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {

        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }

    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera && this.element) {
            this.camera.aspect = width/height;
            this.camera.updateProjectionMatrix();
        }
    }


    protected dispose(): void {
        this.sprite?.material.dispose();
        this.light?.dispose();
        this.dirLightRed?.dispose();
        this.dirLight?.dispose();
        this.ellipse?.geometry.dispose();
        this.shipModel?.geometry.dispose();
        (this.shipModel?.material as MeshPhongMaterial).dispose();
    }




}