import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';

import App from './components/App';
import store from 'STORE/store';

import {OutLiner} from 'a-utils';
new OutLiner();

const root = createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);