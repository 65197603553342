import {useEffect} from "react";

interface useSetActiveProps{
    elementId: string,
    delay?: number
}

export const useSetActive = (items: useSetActiveProps[]) => {

    useEffect(() => {

        const setOpacity = () => {
            // Получаем высоту от верха документа

            items.forEach((el) => {
                    const elem = document.getElementById(el.elementId);
                    if (elem) {

                        if (!el.delay) el.delay =1.5;

                        // Значение паддинга сверху
                        const paddingTop = +window.getComputedStyle(elem, null).paddingTop.split('.')[0].match(new RegExp('[0-9]', 'g'))!.join('');
                        // Значение старта анимации
                        const elementStart = elem.getBoundingClientRect().top + paddingTop - window.innerHeight / el.delay;

                        if (elementStart < 0) {
                            elem.classList.add(`${elem.id + '-active'}`)
                        } else {
                            elem!.classList.remove(`${elem!.id + '-active'}`)
                        }
                    }
            })


        }
        window.addEventListener('scroll', setOpacity);
        return () => {

            window.removeEventListener('scroll', setOpacity);
        }

    }, []);

}
