import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight,
    BufferGeometry,
    Color,
    DirectionalLight, DoubleSide,
    Line,
    LineBasicMaterial,
    LineLoop, MathUtils,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    RepeatWrapping,
    Sprite,
    SpriteMaterial,
    TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {loadingManager} from "./Banner/PreloaderScene";

interface circles{
    model: LineLoop,
    initScale : Vector3,
}


export class ApiScene extends GraphicsScene{


    /**
     * Меш для корабля
     * @private
     */
    private shipModel: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: PerspectiveCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Свет для бликов
     * @private
     */
    private  dirLight : DirectionalLight | null = null;
    private  dirLightRed : DirectionalLight | null = null;

    /**
     *  Координаты для овала
     * @private
     */
    private lineVector: Vector3[] = [];

    /**
     *  Меш для овала
     * @private
     */
    private line: Line| null = null;

    /**
     *  Меш для шарика
     * @private
     */
    private sprite: Sprite | null = null;


    /**
     * Позиция для вертикальной анимации подарка
     * @private
     */
    private swingPosition: number = 0;


    // Индекс увеличения размеров кругов
    private circleScale: number = 0;

    /**
     * Меш для радио волны
     * @private
     */
    private circles : circles[] | null = [];

    /**
     * Массив радиоволн
     * @private
     */
    private circleLine : Vector3[] = []

    /**
     * Позиция скролла
     * @private
     */
    private scrollPosition: number = 0;

    /**
     * Дельта для плавного скролла
     * @private
     */
    private time: number = 0;


    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const loader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели корабля
        const loadModel = await loader.loadAsync("./models/stationM.glb");

        //  Установка модели корабля
        this.shipModel = loadModel.scene.getObjectByName("station") as Mesh;
        this.shipModel.material = new MeshPhongMaterial({
            map: texture,
            color: "#8AA1FF",
            shininess: 30,
            transparent: true,
            side: DoubleSide
        });

        // Установка камеры
        this.camera = new PerspectiveCamera(45, 1, 0.01, 100);

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight();
        this.dirLightRed = new DirectionalLight();


        //Загрузка текстуры точки
        const dotMap = textureLoader.load( './models/textures/ellipse.png' );
        const dotMaterial = new SpriteMaterial( { map: dotMap, transparent: true } );

        // Координаты для антенны
        this.lineVector =[
            new Vector3(0, 0, 0),
            new Vector3(0, 0.5, 0)
        ];

        // Инит геометрия для антенны
        const lineGeometry = new BufferGeometry().setFromPoints( this.lineVector );

        // Инит материа для антенны
        const material = new LineBasicMaterial({
            color: "black",
            transparent: true,
        });

        // Инит линии
        this.line = new Line( lineGeometry, material );

        // Инит спрайта
        this.sprite = new Sprite( dotMaterial );

        // Координаты кругов
        for (let i = 0; i <50; i++) {

            const dist =1.5;
            const angle = Math.PI / 25 * i ;
            const x = Math.sin(angle) * dist;
            const y = Math.cos(angle) * dist;
            this.circleLine?.push(new Vector3(x, y, 0));
        }

        // Инит Кругов
        for (let i = 0; i < 6; i++) {

            const circleMaterial = new LineBasicMaterial({
                color: 0x0,
                transparent: true,
            });

            const circleGeometry = new BufferGeometry().setFromPoints(this.circleLine);
            const model = new LineLoop(circleGeometry, circleMaterial);

            this.circles!.push( {
                model: model,
                initScale: new Vector3(1,1,1),
            })
        }

        let scrollDelay = 100;

        if(window.innerWidth < 1150){
            scrollDelay = 0;
        }
        if(window.innerWidth < 600){
            scrollDelay = 300;
        }

            this.onScroll = this.onScroll.bind(this);
            window.addEventListener("scroll" ,() => this.onScroll(this.element!.getBoundingClientRect().top - scrollDelay));

        renderer.compile(this.scene, this.camera);

    }


    /**
     * Управление позиции сцены на верстке
     * @param scrollPos
     */
    public onScroll(scrollPos: number){
        if (scrollPos < 0 && this.shipModel){
           this.scrollPosition = -scrollPos/300;
        }
    }

    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipModel && this.line && this.sprite){
            this.shipModel.position.set(-1.2,-0.3,-5);
            this.shipModel.rotation.set(Math.PI / 180 * 25,Math.PI / 180 * -150 ,Math.PI / 180 * 22);
            this.shipModel.scale.setScalar(0.7);


            this.line.position.set(-2,1,-6);
            this.line.rotation.z = Math.PI / 180 * -22;


            this.sprite.scale.setScalar(0.05);
            this.sprite.position.set(0, 0.5,0);

            this.line.add(this.sprite);
            this.scene.add(this.line);
            this.scene.add(this.shipModel);
        }

        // Установка света
        if(this.dirLight && this.shipModel &&  this.dirLightRed && this.light){

            // Белый
            this.dirLight.intensity = 0.5;
            this.dirLight.target = this.shipModel;
            this.dirLight.position.set(15,4,10);

            //Красный
            this.dirLightRed.color = new Color("#E786E2");
            this.dirLightRed.target = this.shipModel;
            this.dirLightRed.intensity =0.5;
            this.dirLightRed.position.set(-15,-4,10);

            // Общий
            this.light.intensity = 0.95;
            this.scene.add(this.light);

            this.scene.add(this.dirLight, this.dirLightRed);
        }


        // Установка кругов
        if(this.circles && this.sprite){
            for (let i = 0; i < this.circles.length; i++){

                this.circles[i].initScale = new Vector3(i ,i, i);
                this.circles[i].model.position.set(0,0,0);
                this.circles[i].model.rotation.x = Math.PI /180 * 20;
                this.circles[i].model.rotation.y = Math.PI /180 * -39;
                this.circles[i].model.renderOrder = -5;
                this.sprite.add(this.circles[i].model);
            }
        }

        this.swingPosition = 0;
        this.circleScale = 0;
    }


    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        this.time = MathUtils.damp(this.time, this.scrollPosition, 0.01, delta);

        const smooth = this.time;


        //Качание корабля
        this.swingPosition = (this.swingPosition + 0.03 * delta) % (Math.PI * 2);
        this.circleScale = (this.circleScale + 0.002 * delta) % 2.0;

        // Анимации для корабля
        if (this.shipModel) {
            let scroll = 0
            if (this.scrollPosition > 1 ){
                scroll =  (this.scrollPosition - 1);
            }

            this.shipModel.position.x = (-1.2 + Math.sin(this.swingPosition) * 0.002) + Math.min(3.05, scroll*2.2 *smooth);
            this.shipModel.position.y = ((-0.3 + Math.sin(this.swingPosition) * 0.01) - Math.sqrt(scroll* 5 *  Math.sqrt(smooth*2))/2);
            this.shipModel.rotation.x = (Math.PI / 180 * 25) - Math.min(0.6, scroll/1.2 * smooth);
            this.shipModel.rotation.y = (Math.PI / 180 * -150) + Math.min(2.2, scroll*1.7 * smooth);

            if (this.scrollPosition > 1.5) {
                this.shipModel.scale.setScalar(Math.max(0,0.7 - (this.scrollPosition - 1.5) * smooth ));
            }
            else{
                this.shipModel.scale.setScalar(0.7);

            }
        }

        if (this.line){
            this.line.position.x =  (-1.415 + Math.sin(this.swingPosition) * 0.002) ;
            this.line.position.y = (-0.3 + Math.sin(this.swingPosition) * 0.01);
            (this.line.material as LineBasicMaterial).opacity = 1 - this.scrollPosition * 1.2;


        }

        // Анимация сучих кругов
        if (this.circles && this.sprite){

            (this.sprite.material as SpriteMaterial).opacity = 1- this.scrollPosition;

            for (let i = 0; i < this.circles.length; i++) {
                const circleDelta = (this.circleScale + (1.0 / this.circles.length * i)) % 1.0;
                this.circles[i].model.rotation.y = 0.4 - this.scrollPosition/10;
                this.circles[i].model.scale.setScalar(circleDelta * 15);
                (this.circles[i].model.material as LineBasicMaterial).opacity = 1.0 - circleDelta - this.scrollPosition;



            }

        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {
        this.scrollPosition = 0;

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
            this.sprite?.material.dispose();
            this.light?.dispose();
            this.dirLight?.dispose();
            this.dirLightRed?.dispose();
            window.removeEventListener("scroll" ,() => this.onScroll(this.element!.getBoundingClientRect().top - 100 ));
            this.line?.geometry.dispose();
            this.shipModel?.geometry.dispose();
            (this.shipModel?.material as MeshPhongMaterial).dispose();
    }


}