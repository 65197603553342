import styles from './Primitives.module.scss';

import React from 'react';
import {RawHTML} from 'COMMON/content/primitives/RawHTML';

type Props = {
  children?: string | string[];
  className?: string;
}

const List = ({children}: Props) => <ul>
  {
    Array.isArray(children) ?
      children.map((t, i) => <li key={'li-' + i}>{t}</li>) :
      <li>{children}</li>
  }
</ul>

type PrimitiveTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'blockquote';
type CompositeTag = 'bigP' | 'largeP' | 'ul' | 'html';

export type Primitive = {
  type: PrimitiveTag | CompositeTag;
  isDiv?: boolean;
  content?: string | string[];
}
export type PrimitivesProps = {
  items?: Primitive[];
}


export const Primitives = ({items = []}: PrimitivesProps) => {
  return <div className={styles.primitives}>
    {items!.map(({type, content, isDiv}, i) => {
      if (!type) throw new Error(`"type: ${type}" is not exist`);

      let Child: React.FunctionComponent<Props> | string = type;
      let className;

      if (isDiv) {
        Child = 'div';
        className = type;
      }

      switch (type) {
        case 'bigP':
          Child = 'p';
          className = 'big-text';
          break;
        case 'largeP':
          Child = 'p';
          className = 'h2';
          break;
        case 'ul':
          Child = List;
          break;
        case 'html':
          Child = RawHTML;
          break;
      }

      return <Child key={'ch-' + i} className={className}>{content}</Child>
    })}
  </div>
}


export const PrimitivesSection = (props: PrimitivesProps) => {
  return <section className={styles.section}>
    <div className="wrapper">
      <Primitives {...props}/>
    </div>
  </section>
}