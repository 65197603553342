import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight, DoubleSide,
    LineBasicMaterial, AnimationClip,
    AnimationMixer,
    Mesh, MeshBasicMaterial, OrthographicCamera,
    RepeatWrapping,
    TextureLoader,
    WebGLRenderer, MathUtils
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {loadingManager} from "./Banner/PreloaderScene";

export class EventsScene extends GraphicsScene{


    /**
     * Меш для линейной модели
     * @private
     */
    private shipLineModel: Mesh | null = null;

    /**
     * Меш для крыльев линейной модели
     * @private
     */
    private shipLineWings: Mesh | null = null;

    /**
     * Меш обертка для трубы
     * @private
     */
    private pipeModel: Mesh | null = null;

    /**
     * Меш для трубы
     * @private
     */
    private pipeTubeModel: Mesh | null = null;

    /**
     * Меш для цилиндра
     * @private
     */
    private pipePlugModel: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: OrthographicCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Позиция для вертикальной анимации подарка
     * @private
     */
    private swingPosition: number = 0;

    /**
     * Позиция скролла
     * @private
     */
    private scrollPosition: number = 0;

    /**
     * Задержка анимации при скролле
     * @private
     */
    private scrollDelay: number = 0;


    /**
     * Миксер для аниммации трубы
     * @private
     */
    private mixer : AnimationMixer | null = null;

    /**
     * Дельта для плавного скролла
     * @private
     */
    private time : number = 1;




    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const objLoader = new OBJLoader(loadingManager);
        const gltfLoader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.jpeg');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка линейной модели корабля
        const loadLineModel = await objLoader.loadAsync("./models/Line2.obj");

        // Загрузка модели трубы и цилидра
        const loadPipeModel = await gltfLoader.loadAsync("./models/pipe.glb");

        // Загрузка моделей для линейного корабля
        this.shipLineModel = loadLineModel.getObjectByName('line_Plane.020') as Mesh;
        this.shipLineWings = loadLineModel.getObjectByName("plane_Cube.010") as Mesh;

        this.shipLineModel.material = new LineBasicMaterial({color: "black", transparent: true});
        this.shipLineWings.material = new MeshBasicMaterial({side: DoubleSide, color: 0x0, transparent: true});

        this.pipeModel = loadPipeModel.scene.getObjectByName("Empty") as Mesh;
        this.pipeTubeModel = this.pipeModel.getObjectByName("pipe") as Mesh;
        this.pipePlugModel = this.pipeModel.getObjectByName("message") as Mesh;
        this.pipeTubeModel.material = new MeshBasicMaterial({ color: "#0D0C0C", transparent: true, opacity: 0.5});
        this.pipePlugModel.material = new MeshBasicMaterial({color:"#ADFF00", transparent: true, opacity: 0.8});

        this.mixer = new AnimationMixer( this.pipeModel );
        const clips = loadPipeModel.animations;

        const clip = AnimationClip.findByName( clips, 'Action' );
        const action = this.mixer.clipAction( clip );
        action.play();

        // Установка камеры
        this.camera = new OrthographicCamera(-1, 1, 0, 1, -1, 100);
        this.camera.position.set(0,-0.1,0);

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);

        let scrollDel = 800;

        if (window.innerWidth < 1700){
            scrollDel = 600;
        }
        if (window.innerWidth < 900){
            scrollDel = 900;
        }
        if (window.innerWidth < 500){
            scrollDel = 1100;
        }

        this.onScroll.bind(this.onScroll);
        window.addEventListener('scroll', () => this.onScroll(this.element!.getBoundingClientRect().top - scrollDel),{passive: true});

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        // Установка корабля
        if(this.shipLineModel && this.shipLineWings && this.pipeModel){
            this.shipLineModel.position.set(0.32,0.35,-5);
            this.shipLineModel.rotation.set(Math.PI/180 * 148.5, Math.PI/180 * 47.2, Math.PI/180 * -5);
            this.shipLineModel.scale.setScalar(0.205);
            this.shipLineModel.add(this.shipLineWings, this.pipeModel);
            this.scene.add(this.shipLineModel);
        }

        // Установка света
        if(this.light){

            // Общий
            this.light.intensity = 0.95;

            this.scene.add(this.light)

        }

        this.swingPosition = 0;

    }

    /**
     * Управление позиции сцены на верстке
     * @param scrollPos
     */
    public onScroll(scrollPos: number){

        if (scrollPos < 0){
            this.scrollPosition = -scrollPos/100;
        }
    }



    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        this.time = MathUtils.damp(this.time, this.scrollPosition, 0.008, delta);
        let smooth = (this.time/4) - 0.5;
        if (window.innerWidth < 1023){
            smooth = 1;
        }

        if (this.mixer){
            const animationSpeed = 0.016;
            this.mixer.update( delta * animationSpeed);
        }


        if (window.innerWidth < 500 && this.shipLineModel){
            this.shipLineModel.scale.setScalar(0.16);
        }

        //Качание корабля
        this.swingPosition = (this.swingPosition + 0.03 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipLineModel && this.shipLineWings && this.pipeTubeModel && this.pipePlugModel){

            let minPos =  0.6;

            if (window.innerWidth < 850){
                minPos = 0.5;
            }

            let posX = Math.min(minPos,( this.scrollPosition *  smooth)/30) ;
            let posY = Math.min(minPos, ( this.scrollPosition * smooth)/30) ;

            if (window.innerWidth < 1023){
                // posY = Math.min(minPos, (1 + this.scrollPosition  * smooth)/22);
            }


            const swingY = Math.sin(this.swingPosition) * 0.005;

            this.shipLineModel.position.y = posY + swingY;
            this.shipLineModel.position.x = 0.32 - posX;

            const opacityDelay = 2.2;
            (this.shipLineModel.material as LineBasicMaterial).opacity =  Math.min(1, this.scrollPosition *  smooth/2 - opacityDelay);
            (this.shipLineWings.material as MeshBasicMaterial).opacity =  Math.min(1, this.scrollPosition *  smooth/2 - opacityDelay);
            (this.pipeTubeModel.material as MeshBasicMaterial).opacity = Math.min(0.5, this.scrollPosition *  smooth/2 - opacityDelay);
            (this.pipePlugModel.material as MeshBasicMaterial).opacity = Math.min(0.8, this.scrollPosition *  smooth/2 - opacityDelay);

        }
        if (this.camera){
            if (window.innerWidth < 500){
                this.camera.position.set(-0.25 + window.innerWidth/3500, -0.1,0);
            }
        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            const aspectRatio = width/height;
            this.camera.left = -aspectRatio / 2.0;
            this.camera.right = aspectRatio / 2.0;
            this.camera.updateProjectionMatrix();

        }
        if (window.innerWidth < 640){
            this.scrollDelay = 500;
        }
        if (window.innerWidth < 430){
            this.scrollDelay = 500;
        }
    }



    protected dispose(): void {

    }




}