import {GraphicsScene} from "./GraphicsScene";
import {
    AmbientLight, BackSide, DoubleSide, DirectionalLight,
    LineBasicMaterial,
    Mesh, MeshBasicMaterial, MeshPhongMaterial, OrthographicCamera,
    RepeatWrapping,
    TextureLoader,
    Vector3,
    WebGLRenderer, MeshLambertMaterial
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {loadingManager} from "./Banner/PreloaderScene";


interface TrashEntry{
    model : Mesh,
    initPosition: Vector3,
    speed: number
}

export class ServicesScene extends GraphicsScene{


    /**
     * Меш для корабля
     * @private
     */
    private shipModel: Mesh | null = null;

    /**
     * Меш для линейной модели
     * @private
     */
    private shipLineModel: Mesh | null = null;

    /**
     * Масиив мешей для мусора
     * @private
     */
    private trashModels: TrashEntry[] = [];

    /**
     * Меш для шлюза(мусор)
     * @private
     */
    private portHoleModel: Mesh | null = null;

    /**
     * Меш для пружины(мусор)
     * @private
     */
    private springModel: Mesh | null = null;

    /**
     * Меш для трубы(мусор)
     * @private
     */
    private tubeModel: Mesh | null = null;

    /**
     * Меш для крыла(мусор)
     * @private
     */
    private wingModel: Mesh | null = null;

    /**
     * Меш для малого крыла(мусор)
     * @private
     */
    private wingSmallModel: Mesh| null = null;

    /**
     * Меш для крыльев линейной модели
     * @private
     */
    private shipLineWings: Mesh | null = null;

    /**
     * Камера
     * @private
     */
    private camera: OrthographicCamera | null = null;

    /**
     * Свет
     * @private
     */
    private light: AmbientLight | null = null;

    /**
     * Направленный светильник
     * @private
     */
    private dirLight: DirectionalLight | null = null;

    /**
     * Направленный светильник красный
     * @private
     */
    private dirLightRed : DirectionalLight | null = null;

    /**
     * Позиция для вертикальной анимации подарка
     * @private
     */
    private swingPosition: number = 0;

    /**
     * Позиция скролла
     * @private
     */
    private scrollPosition: number = 0;

    /**
     * Скейл для кораблей
     * @private
     */
    private shipsScale : number = 0;

    /**
     * Загрузка и инит сцены
     * @protected
     */
    protected async load(renderer: WebGLRenderer) {

        //  Инит лоадера
        const objLoader = new OBJLoader(loadingManager);
        const gltfLoader = new GLTFLoader(loadingManager);
        const textureLoader = new TextureLoader(loadingManager);

        // Загрузка текстуры  корабля
        const texture = textureLoader.load('./models/textures/shipTexture.png');
        texture.flipY = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        // Загрузка модели корабля
        const loadModel = await gltfLoader.loadAsync("./models/stationM.glb");

        //  Установка модели корабля
        this.shipModel = loadModel.scene.getObjectByName("station") as Mesh;

        this.shipModel.material = new MeshPhongMaterial({
            map: texture,
            color: 0x8AA1FF,
            shininess: 100,
            side: BackSide,
            transparent: true
        });

        // Загрузка линейной модели корабля
        const loadLineModel = await objLoader.loadAsync("./models/Line2.obj");

        // Загрузка моделей для линейного корабля
        this.shipLineModel = loadLineModel.getObjectByName('line_Plane.020') as Mesh;
        this.shipLineWings = loadLineModel.getObjectByName("plane_Cube.010") as Mesh;
        this.shipLineModel.material = new LineBasicMaterial({color: "black", transparent: true, opacity: 0})
        this.shipLineWings.material = new MeshBasicMaterial({side: DoubleSide, color: 0x0, transparent: true, opacity: 0})

        // Загрузка мусора
        const loadTrashModel = await  objLoader.loadAsync("./models/trashLine.obj");

        // Загрузка мешей для мусора
        this.portHoleModel = loadTrashModel.getObjectByName("porthole_Plane.004") as Mesh;
        this.tubeModel = loadTrashModel.getObjectByName("tube_Plane.002") as Mesh;
        this.springModel = loadTrashModel.getObjectByName("sluice_Plane.003") as Mesh;
        this.wingModel = loadTrashModel.getObjectByName("longwing_Cube.001") as Mesh;
        this.wingSmallModel = loadTrashModel.getObjectByName("shortwing_Cube.002") as Mesh;

        // Установка камеры
        this.camera = new OrthographicCamera(-1, 1, 0, 1, -1, 100);
        this.camera.zoom = 0.5;

        // Установка света
        this.light = new AmbientLight("#D3E2FF", 1);
        this.dirLight = new DirectionalLight("white");
        this.dirLightRed = new DirectionalLight("red");

        let scrollDelay = 1300;

        if (window.innerWidth < 850){
            scrollDelay = 1500;
        }
        if (window.innerWidth < 500){
            scrollDelay = 1700;
        }

        // Установка эвентов
        this.onScroll.bind(this.onScroll);
        window.addEventListener('scroll', () => this.onScroll(this.element!.getBoundingClientRect().top - scrollDelay),{passive:true});

        renderer.compile(this.scene, this.camera);
    }


    /**
     * Установка позиций
     * @protected
     */
    protected enter(): void {

        this.shipsScale = Math.min(0.22, window.innerWidth/3500);

        if (window.innerWidth < 900){
            this.shipsScale = 0.2 + window.innerWidth/20000;
        }

        if (window.innerWidth < 600){
            this.shipsScale = 0.1 + window.innerWidth/8000;
        }

        // Установка корабля
        if  (this.shipModel){
            this.shipModel.position.set(0,0.42,-15);
            this.shipModel.rotation.set(Math.PI/180 * 148.5, Math.PI/180 * 47.2, Math.PI/180 * -5);
            this.shipModel.scale.setScalar(this.shipsScale);

            this.scene.add(this.shipModel);
        }

        // Установка линейного корабля
        if(this.shipLineModel && this.shipLineWings){
            this.shipLineModel.position.set(-0.4,1.37,-5);
            this.shipLineModel.rotation.set(Math.PI/180 * 148.5, Math.PI/180 * 47.2, Math.PI/180 * -5);
            this.shipLineModel.scale.setScalar(this.shipsScale);

            this.shipLineModel.add(this.shipLineWings);
            this.scene.add(this.shipLineModel);
        }

        // Установка мусора
        if (this.wingModel && this.wingSmallModel && this.tubeModel && this.springModel && this.portHoleModel){
            const addTrash = (trashModel : Mesh, amount: number, speed: number, scale: number) => {
                for (let i = 1; i < amount; i++) {
                    const model = trashModel.clone();
                    model.material = new LineBasicMaterial({color: "black", side: DoubleSide, transparent: true, opacity: 1});

                    let xPos = 0;
                    if (i % 2 === 0) {
                        xPos =  Math.random() * 300 +100;
                    }
                    else {
                        xPos = -(Math.random() * 100 + 400);
                    }

                    if (window.innerWidth < 1200){
                        xPos = Math.random() * 800 - 370;
                    }

                    let initPos = new Vector3(xPos/1000,(Math.random()* 40 + 50)/100,-6);
                    if (window.innerWidth < 1200){
                        initPos = new Vector3(xPos/1000,(Math.random()* 50+ 10)/100,-6);

                    }
                    model.position.set(initPos.x, initPos.y, initPos.z);
                    model.scale.setScalar(scale/500);
                    this.trashModels.push({
                        model: model,
                        initPosition: initPos,
                        speed: Math.random() * 2 + speed
                    })
                    this.scene.add(model);
                }
            }

            if (window.innerWidth > 500){
                addTrash(this.wingModel, 2, 2, 48);
                addTrash(this.wingSmallModel, 5, 4, 40);
                addTrash(this.tubeModel, 3,3, 50);
                addTrash(this.springModel, 4,3, 70);
                addTrash(this.portHoleModel, 4,3, 50);
            }
            else{
                addTrash(this.wingModel, 2, 2, 48);
                addTrash(this.wingSmallModel, 4, 5, 40);
                addTrash(this.tubeModel, 2,3, 50);
                addTrash(this.springModel, 3,3, 70);
                addTrash(this.portHoleModel, 3,4, 50);
            }


        }



        // Установка света
        if(this.light && this.dirLight && this.dirLightRed){

            // Общий
            this.light.intensity = 0.95;

            //Белый
            this.dirLight.target = this.shipModel as Mesh;
            this.dirLight.position.set(20,30,-30);
            this.dirLight.intensity = 0.65;

            // Красный
            this.dirLightRed.target = this.shipModel as Mesh;
            this.dirLightRed.position.set(-15,-5,-10);
            this.dirLightRed.intensity = 0.4;

            this.scene.add(this.light, this.dirLight, this.dirLightRed);

        }

        if (window.innerWidth < 500 && this.camera){
            this.camera.position.set(0,0.2,0);
        }

        this.swingPosition = 0;
    }

    /**
     * Управление позиции сцены на верстке
     * @param scrollPos
     */
    public onScroll(scrollPos: number){

        if (scrollPos < 0 && this.shipModel){
            this.scrollPosition = -scrollPos/100;
        }
    }



    /**
     * Анимации
     * @param delta Время
     * @param visible
     * @protected
     */
    protected update(delta: number, visible: boolean): void {

        //Качание корабля
        this.swingPosition = (this.swingPosition + 0.03 * delta) % (Math.PI * 2);

        // Анимации для корабля
        if (this.shipLineModel && this.shipModel && this.shipLineWings){
            this.shipModel.position.x =  Math.sin(this.swingPosition) * 0.002;
            this.shipModel.position.y = (0.12 + Math.sin(this.swingPosition) * 0.01 - 0.21);

            this.shipLineModel.position.x =  Math.sin(this.swingPosition) * 0.002;
            this.shipLineModel.position.y = (0.12 + Math.sin(this.swingPosition) * 0.01 - 0.21);

        }

        //Мусор


        if (this.trashModels){
            for (const [i,model] of this.trashModels.entries()) {

                model.model.rotation.x += 0.001 *  model.speed * delta;
                model.model.rotation.y += 0.001 *  model.speed * delta;
                model.model.rotation.z += 0.001 *  model.speed * delta;
                model.model.position.y -= 0.0001 *  model.speed * delta;



                // Перенос модели вниз
                if  (window.innerWidth > 1200){
                    if (model.model.position.y < 0.45){
                        let xPos = 0;
                        let yPos = (Math.random()* 10 + 95)/100;

                        // Каждая 3я слева
                        if (i % 2 === 0) {
                            xPos =  Math.random() * 300 +100;
                        }
                        else {
                            xPos = -(Math.random() * 100 + 400);
                        }

                        model.initPosition = new Vector3(xPos/1000, yPos, -5);
                        model.model.position.set(model.initPosition.x, model.initPosition.y - 0.01 * delta, model.initPosition.z);
                        (model.model.material as LineBasicMaterial).opacity = 1;
                    }

                    // Прозрачность
                    if  (model.model.position.y  < 0.60){
                        (model.model.material as LineBasicMaterial).opacity = 1 + (model.model.position.y - 0.55)*20;
                    }
                    if (model.model.position.y  > 0.9){
                        (model.model.material as LineBasicMaterial).opacity = 0 - (model.model.position.y - 0.95)*30;
                    }
                }

                else
                {

                    if (model.model.position.y < 0.3){

                        const xPos = Math.random() * 800 - 370;

                        model.initPosition = new Vector3(xPos/1000, (Math.random()* 5 + 60)/100, -5);
                        model.model.position.set(model.initPosition.x, model.initPosition.y - 0.01 * delta, model.initPosition.z);
                        (model.model.material as LineBasicMaterial).opacity = 1;
                    }

                    // Прозрачность
                        if  (model.model.position.y  < 0.4){
                            (model.model.material as LineBasicMaterial).opacity = 1 + (model.model.position.y - 0.4)*20;
                        }
                        if (model.model.position.y  > 0.5){
                            (model.model.material as LineBasicMaterial).opacity = 0 - (model.model.position.y - 0.6)*20;
                        }

                    }

                }
            }

        // Таймлайн
        if (this.shipLineModel && this.shipModel && this.shipLineWings){
                let animationDelay = 15.2;
                if(window.innerWidth > 1440){
                    animationDelay = 14.2;
                }

            if(window.innerWidth < 1440){
                animationDelay = 14.2 - window.innerHeight/1000;
            }

                // Прозрачность
                (this.shipModel.material as MeshLambertMaterial).opacity = (1 + (animationDelay - this.scrollPosition)/3);
                (this.shipLineWings.material as MeshBasicMaterial).opacity = (-animationDelay + this.scrollPosition)/3;
                (this.shipLineModel.material as LineBasicMaterial).opacity = (-animationDelay + this.scrollPosition)/3;

               //Сдвиг модели вниз

                if (this.scrollPosition > animationDelay){
                    const scrollStart = animationDelay + 1.5;
                    const shipMinPos = 0.46;
                    let scrollSpeed = (scrollStart - this.scrollPosition)/16;

                    if (window.innerWidth < 1440){
                        scrollSpeed = (scrollStart - this.scrollPosition)/(16 - window.innerHeight/500);
                    }

                    this.shipLineModel.position.y = (Math.sin(this.swingPosition) * 0.01 + Math.min(shipMinPos, -scrollSpeed));
                    this.shipModel.position.y = (Math.sin(this.swingPosition) * 0.01 + Math.min(shipMinPos, -scrollSpeed));
                }

        }
    }


    /**
     * Рендер
     * @param renderer
     * @protected
     */
    protected render(renderer: WebGLRenderer): void {
        if (this.camera) {
            renderer.render(this.scene, this.camera);
        }
    }


    protected leave(): void {

    }

    protected resize(width: number, height: number): void {
        if (this.camera) {
            const aspectRatio = width/height;
            this.camera.left = -aspectRatio / 2.0;
            this.camera.right = aspectRatio / 2.0;
            this.camera.updateProjectionMatrix();
        }
    }



    protected dispose(): void {
        this.light?.dispose();
        for (const trash of this.trashModels) {
            trash.model.geometry.dispose();
            (trash.model.material as LineBasicMaterial).dispose();
        }
        this.shipLineModel?.geometry.dispose();
        (this.shipLineModel?.material as LineBasicMaterial).dispose();
        this.shipModel?.geometry.dispose();
        (this.shipModel?.material as MeshPhongMaterial).dispose();
        this.dirLightRed?.dispose();
        this.dirLight?.dispose();
    }




}