import React, {useRef, useContext, useEffect} from 'react';
import styles from './AboutExtra.module.scss'
import {ManagerContext} from "COMPONENTS/Wrapper/Wrapper";
import clsx from 'clsx';
import {AuthScene} from "../../graphics/scenes/Extra/AuthScene";
import {CloudScene} from "../../graphics/scenes/Extra/CloudScene";
import {FeatureScene} from "../../graphics/scenes/Extra/FeatureScene";
import {useCircle} from "../../graphics/scenes/CircleScene";
import {Color} from "three";


const AboutExtra = () => {

    const authRef = useRef<HTMLDivElement>(null);
    const cloudRef = useRef<HTMLDivElement>(null);
    const featureRef = useRef<HTMLDivElement>(null);

    const authCircle = useRef<HTMLDivElement>(null);
    const cloudCircle = useRef<HTMLDivElement>(null);
    const featureCircle = useRef<HTMLDivElement>(null);
    const titleCircle = useRef<HTMLDivElement>(null);
    const titleCircleRed = useRef<HTMLDivElement>(null);




    const manager = useContext(ManagerContext);

    // Инит сцены в блоке Authentication
    useEffect(() => {
        if(manager){
            if (!manager || !authRef.current) return;

            const scene = new AuthScene(authRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, authRef.current])

    // Инит сцены в блоке Cloud
    useEffect(() => {
        if(manager){
            if (!manager || !cloudRef.current) return;

            const scene = new CloudScene(cloudRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, cloudRef.current])

    // Инит сцены в блоке Feature
    useEffect(() => {
        if(manager){
            if (!manager || !featureRef.current) return;

            const scene = new FeatureScene(featureRef.current);
            manager.addScene(scene);

            return () => {
                manager.removeScene(scene);
                scene.disposeScene();
            }
        }
    },[manager, featureRef.current])


    useCircle(manager, authCircle, {color: new Color("red"), scale: 8});
    useCircle(manager, cloudCircle, {color: new Color("red"), scale: 8});
    useCircle(manager, featureCircle, {color: new Color("red"), scale: 8});
    useCircle(manager, titleCircle, {color: new Color("#afff00"), scale: 8});
    useCircle(manager, titleCircleRed, {color: new Color("red"), scale: 4, opacity: 0.05, renderOrder: 20});




    return (
        <div className={styles.ExtraWrapper} id={"other"} >
            <div className={styles.ExtraWrapperCircle}>
                <div className={styles.TitleCircleGreen} ref={titleCircle}></div>
                <div className={styles.TitleCircleRed} ref={titleCircleRed}></div>

            </div>

            <h2>кроме основных сервисов есть еще и дополнительные&nbsp;—&nbsp;они тоже важны</h2>

            <div className={styles.ExtraItemList} id={"other-list"}>

                <div className={styles.ExtraItem}>
                    <div className={clsx(styles.ExtraItemScene, styles.Scene)} ref={authRef}></div>
                    <div className={styles.ExtraItemCircle} ref={authCircle}></div>
                    <h4>Пропуск на сервис: Authentication Service</h4>
                    <p>Каждый гость, прибывающий на&nbsp;нашу станцию, должен показать свой космический паспорт, чтобы мы&nbsp;поняли,
                       <br/> кто это такой. Это аутентификация. После проверки паспорта мы&nbsp;выдаем ему пропуск. В&nbsp;зависимости от&nbsp;роли гостя, этот пропуск открывает ему двери в&nbsp;некоторые отсеки, а&nbsp;в&nbsp;некоторые&nbsp;—&nbsp;нет. Это авторизация.
                    </p>
                    <p> Для наших микросервисов в&nbsp;качестве пропуска мы&nbsp;используем технологию JWT, а&nbsp;в&nbsp;качестве сервиса аутентификации/авторизации используем Keycloack.
                       <br/> Это сервер с&nbsp;открытым кодом, который поддерживает основные протоколы и&nbsp;дает нужный нам функционал.
                    </p>
                </div>

                <div className={styles.ExtraItem}>
                    <div className={clsx(styles.ExtraItemScene, styles.Scene)}  ref={cloudRef}></div>
                    <div className={styles.ExtraItemCircle} ref={cloudCircle}></div>
                    <h4>Пульт управления
                        компонентами: Cloud Config</h4>
                    <p>Когда наша станция проходит через облако обломков космического мусора, нам нужно снижать напряжение
                        в&nbsp;бортовой сети. Еще мы&nbsp;хотим централизованно управлять настройками всех модулей с&nbsp;единого пульта управления.
                    </p>
                    <p>  В микросервисной архитектуре такую роль выполняет Cloud Config. Мы используем готовое решение на&nbsp;базе Consul. Это позволяет централизованно менять настройки и конфигурировать сервисы.</p>
                </div>

                <div className={styles.ExtraItem}>
                    <div  className={clsx(styles.ExtraItemScene, styles.Scene)}  ref={featureRef}></div>
                    <div className={styles.ExtraItemCircle} ref={featureCircle}></div>
                    <h4>Переключение функционала: Feature Toggles</h4>
                    <p> В праздники хочется, чтобы во&nbsp;всех модулях космической станции играла музыка и мигала подсветка. Если Cloud Config&nbsp;—&nbsp;это пульт, который используют инженеры,
                        то&nbsp;Feature Toggles&nbsp;—&nbsp;это диджейский микшер: панель
                        с&nbsp;кучей переключателей и настроек, чтобы сделать пребывание на станции незабываемым.</p>
                    <p> Мы адаптировали Unleash&nbsp;—&nbsp;решение с&nbsp;открытым кодом, позволяющее раскатывать новый функционал только
                       <br/> на&nbsp;бета-тестеров, проводить A/B тестирование и&nbsp;проверять гипотезы.
                    </p>
                </div>

            </div>
        </div>
    );
};

export default AboutExtra;