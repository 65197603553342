import {MetaData, useMeta} from './meta/meta';
import {PrimitivesSection} from 'COMMON/content/primitives/Primitives';
import {VideoSection} from 'COMMON/content/video/Video';

type TypeOfContent = typeof content;
type ContentKeys = keyof TypeOfContent;

interface ContentType<T extends ContentKeys> {
  type: T;
  content: Parameters<TypeOfContent[T]>[0];
}

export type ContentTypes = (
  // ContentType<'test'> |
  ContentType<'text'> |
  ContentType<'video'>
)[];

export type ContentProps = {
  items?: ContentTypes;
  meta?: MetaData;
}


const content = {
  // test: TestComponent,
  text: PrimitivesSection,
  video: VideoSection
}


export const Content = ({items = [], meta: data}: ContentProps) => {
  // useMeta(data);

  return <>
    {items!.map((item, i) => {
      if (!(item.type in content)) throw new Error(`"type: ${item.type}" is not exist`);

      const ContentChild = content[item.type];
      // @ts-ignore
      return <ContentChild key={'ci-' + i} {...item.content}/>
    })}
  </>
}