import {Scene, WebGLRenderer,} from 'three';


/**
 * Статус сцены
 */
export enum SceneState {
    None,
    Loading,
    Loaded,
    Active,
    Disposed,
}

/**
 * Сцена-экран
 */
export abstract class GraphicsScene {

    /**
     * Элемент, к которому надо привязаться
     */
    public readonly element: HTMLElement | null;

    /**
     * Вложенная тришная сцена
     * @private
     */
    protected readonly scene: Scene;

    /**
     * Размеры для рендера
     * @protected
     */
    protected readonly targetRect: DOMRect;

    /**
     * Колбэк на загрузку сцены
     */
    public onReady: ((scene: GraphicsScene) => void) | null = null;

    /**
     * Статус сцены
     * @private
     */
    private state: SceneState = SceneState.None;

    /**
     * Флаг активной сцены
     * @private
     */
    public active: boolean = true;

    /**
     * Конструктор сцены
     */
    public constructor(element: HTMLElement | null = null) {
        this.element = element;
        this.targetRect = new DOMRect(0, 0, 0, 0);
        this.scene = new Scene();
    }

    /**
     * Обновление логики сцены
     * @param delta
     * @param visible
     */
    public updateLogic(delta: number, visible: boolean) {
        if (this.state === SceneState.Loaded) {
            this.sceneAdded();
        }
        if (this.state === SceneState.Active) {
            this.update(delta, visible);
        }
    }

    /**
     * Отрисовка одного кадра
     */
    public renderFrame(renderer: WebGLRenderer) {
        if (this.state !== SceneState.Active) return;

        // Сбрасываем все состояния и ставим вьюпорт
        renderer.setScissorTest(true);
        renderer.setRenderTarget(null);
        renderer.setScissor(
            this.targetRect.x,
            this.targetRect.y,
            this.targetRect.width,
            this.targetRect.height
        );
        renderer.setViewport(
            this.targetRect.x,
            this.targetRect.y,
            this.targetRect.width,
            this.targetRect.height
        );
        renderer.clearDepth();

        // Рендерим кадр
        this.render(renderer);

        // Отключаем scissor-тест и рендертаргет
        renderer.setScissorTest(false);
    }

    /**
     * Внешняя предзагрузка ресурсов
     * @protected
     */
    public async preloadResources(renderer: WebGLRenderer) {
        if (this.state === SceneState.None) {
            this.state = SceneState.Loading;
            try {
                await this.load(renderer);
                this.state = SceneState.Loaded;
                if (this.onReady) {
                    this.onReady(this);
                }
            } catch (ex) {
                this.state = SceneState.Disposed;
                console.warn('Сцена не загрузилась', ex);
            }
        }
    }

    /**
     * Сцена добавляется в список
     */
    public sceneAdded() {
        if (this.state === SceneState.Loaded) {
            this.state = SceneState.Active;
            this.resize(this.targetRect.width, this.targetRect.height);
            this.enter();
        }
    }

    /**
     * Сцена удаляется из списка
     */
    public sceneRemoved() {
        if (this.state === SceneState.Active) {
            this.state = SceneState.Loaded;
            this.leave();

        }
    }

    /**
     * Удаление ресурсов сцены
     */
    public disposeScene() {
        if (this.state === SceneState.Active || this.state === SceneState.Loaded) {
            this.dispose();
            this.state = SceneState.Disposed;
        }
    }

    /**
     * Предзагрузка ресурсов
     * @protected
     */
    protected abstract load(renderer?: WebGLRenderer): Promise<void>;

    /**
     * Активация экрана
     * @protected
     */
    protected abstract enter(): void;

    /**
     * Деактивация экрана
     * @protected
     */
    protected abstract leave(): void;

    /**
     * Освобождение ресурсов
     * @protected
     */
    protected abstract dispose(): void;

    /**
     * Внутреннее обновление логики сцены
     * @param delta
     * @param visible
     * @protected
     */
    protected abstract update(delta: number, visible: boolean): void;

    /**
     * Отрисовка сцены
     * @param renderer
     * @protected
     */
    protected abstract render(renderer: WebGLRenderer): void;

    /**
     * Обновление размеров
     * @param width
     * @param height
     * @protected
     */
    protected abstract resize(width: number, height: number): void;

    /**
     * Обновление сцены
     * @param rawSize
     */
    public updateRenderSize(rawSize: DOMRect) {
        if (
            rawSize.x !== this.targetRect.x ||
            rawSize.y !== this.targetRect.y ||
            rawSize.width !== this.targetRect.width ||
            rawSize.height !== this.targetRect.height
        ) {
            let changeSize = false;
            if (
                rawSize.width !== this.targetRect.width ||
                rawSize.height !== this.targetRect.height
            ) {
                changeSize = true;
            }
            this.targetRect.x = rawSize.x;
            this.targetRect.y = rawSize.y;
            this.targetRect.width = rawSize.width;
            this.targetRect.height = rawSize.height;
            if (changeSize) {
                this.resize(this.targetRect.width, this.targetRect.height);
            }
        }
    }

    /**
     * Слой сцены
     */
    public getOrder() {
        return 0;
    }

    /**
     * Получение размера для рендера
     * @protected
     */
    public getSize() {
        return new DOMRect(0, 0, 0, 0);
    }

    /**
     * Нужен ли ререндер сцены
     * @protected
     */
    public needRepaint() {
        return true;
    }

    /**
     * Нужен ли внеэкранный апдейт
     * @protected
     */
    public needOffscreenUpdate() {
        return false;
    }

}
